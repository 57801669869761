import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import Obfuscate from 'react-obfuscate';

const styles = () => ({
    language: {
        textDecoration: 'none',
        color: 'black',
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    tel: {
        textDecoration: 'none',
        color: 'black',
        "&:hover": {
            textDecoration: 'none',
        },
    }
})

class ContactBlock extends Component {

    render() {
        const {classes} = this.props;

        return (
            <p>
                <b>
                    <FormattedMessage
                        id="imprint.name"
                        defaultMessage="Lütje Steuerungstechnik GmbH & Co. KG"
                    />
                </b><br/>
                <FormattedMessage
                    id="imprint.street"
                    defaultMessage="Himmelreichstraße 2"
                /><br/>
                <FormattedMessage
                    id="imprint.city"
                    defaultMessage="78333 Stockach"
                /><br/>
                <FormattedMessage
                    id="imprint.tel"
                    defaultMessage="Tel: "
                />
                <Obfuscate
                    className={classes.tel}
                    tel={"+49 (0) 175 2471061"}
                />
                <br/>
                <FormattedMessage
                    id="imprint.fax"
                    defaultMessage="Fax: "
                />
                <Obfuscate
                    className={classes.tel}
                    tel={" +49 (0) 7771 873509"}
                /><br/>
                <FormattedMessage
                    id="imprint.eMail"
                    defaultMessage="E-Mail: "
                />
                <Obfuscate
                    className={classes.language}
                    email={this.props.lang === 'de' ? "tluetje@tluetje.de" : "tluetje@cnc-manager.com"}
                />
            </p>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(ContactBlock);