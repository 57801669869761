import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import spacer from "../res/spacer";
import palette from "../res/palette";

const styles = () => ({
    root: {
        marginBottom: spacer.big,
        border: palette.border.main,
    },
    indicatorRectangle: {
        maxWidth: '15px',
    },
    indicatorLine: {
        maxWidth: '15px',
        maxHeight: '5px',
    },
})

class MyCarousel extends Component {
    render() {
        const {classes} = this.props;

        return (
            <Carousel
                className={classes.root}
                dynamicHeight={false}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={7000}
                fade={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = {marginLeft: 20, color: "white", cursor: "pointer"};
                    const style = isSelected
                        ? {...defStyle, color: "red"}
                        : {...defStyle};
                    return (

                        <span
                            style={style}
                            onClick={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            aria-label={`${label} ${index + 1}`}
                        >
                            {isSelected ?
                                <img className={classes.indicatorRectangle} src={'/images/cnc_logo_kasten.png'}
                                     alt={'Kasten'}/> :
                                <img className={classes.indicatorLine} src={'/images/cnc_logo_kasten.png'}
                                     alt={'Linie'}/>
                            }
                        </span>
                    );
                }}
            >
                {this.props.children}
            </Carousel>
        );
    }
}

export default withStyles(styles, {withTheme: true})(MyCarousel);