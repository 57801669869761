import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselElement from "../../components/CarouselElement";
import MyCarousel from "../../components/MyCarousel";
import getImage from "../../res/imgs";
import {FormattedMessage} from "react-intl";

//TODO: Bild für IT Support
class HomeCarousel extends Component {
    render() {
        return (
            <MyCarousel>
                <CarouselElement
                    imageSrc={getImage(this.props.lang).halle.src}
                    header={<FormattedMessage
                        id="carousel.about.header"
                        defaultMessage="Your CNC control technician"
                    />}
                    text={<FormattedMessage
                        id="carousel.about.main"
                        defaultMessage="Years of experience in the fields of control engineering from repair to commissioning of electronics"
                    />}
                    to={"/About"}
                    toText={<FormattedMessage
                        id="navbar.about"
                        defaultMessage="About us"
                    />}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).statusPage.src}
                    header={<FormattedMessage
                        id="carousel.manager.header"
                        defaultMessage="The CNCManager"
                    />}
                    text={<FormattedMessage
                        id="carousel.manager.main"
                        defaultMessage="The ideal software solution for diagnostics, data transfer and data backup"
                    />}
                    to={"/CNC/Manager"}
                    toText={<FormattedMessage
                        id="navbar.cnc.manager"
                        defaultMessage="CNCManager"
                    />}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).cncMonitoring.src}
                    header={<FormattedMessage
                        id="carousel.monitoring.header"
                        defaultMessage="The CNCMonitoring"
                    />}
                    text={<FormattedMessage
                        id="carousel.monitoring.main"
                        defaultMessage="The application to collect, evaluate and visualize your production data"
                    />}
                    to={"/CNC/Monitoring"}
                    toText={<FormattedMessage
                        id="navbar.cnc.monitoring"
                        defaultMessage="CNCMonitoring"
                    />}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).itMain.src}
                    header={<FormattedMessage
                        id="carousel.it.header"
                        defaultMessage="Your IT-Support"
                    />}
                    text={<FormattedMessage
                        id="carousel.it.main"
                        defaultMessage="The support for hardware and software problems for small and medium-sized enterprises"
                    />}
                    to={"/IT"}
                    toText={<FormattedMessage
                        id="navbar.it"
                        defaultMessage="IT-Support"
                    />}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
            </MyCarousel>
        );
    }
}

export default HomeCarousel;