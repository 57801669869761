import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import CNCElement from "../../components/CNCElement";
import spacer from "../../res/spacer";
import getImage from "../../res/imgs";
import {FormattedMessage} from "react-intl";

const styles = () => ({
    container: {
        paddingTop: spacer.medium,
    },
    grid: {
        paddingTop: 0,
    }
})

class CNC extends Component {

    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'CNC-Lösungen' : 'CNC-Solutions';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={
                <FormattedMessage
                    id="navbar.cnc"
                    defaultMessage="CNC-Solutions"
                />
            }>
                <Grid
                    container
                    className={classes.container}
                >
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}>
                        <CNCElement
                            imageSrc={getImage(this.props.lang).cncMonitoring.src}
                            imageAlt={getImage(this.props.lang).cncMonitoring.alt}
                            to={"/CNC/Monitoring"}
                            toText={
                                <FormattedMessage
                                    id="navbar.cnc.monitoring"
                                    defaultMessage="CNCMonitoring"
                                />}
                            text={<FormattedMessage
                                id="carousel.monitoring.main"
                                defaultMessage=  "The application to collect, evaluate and visualize your production data"
                            />}
                        />
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}>
                        <CNCElement
                            imageSrc={getImage(this.props.lang).statusPage.src}
                            imageAlt={getImage(this.props.lang).statusPage.alt}
                            to={"/CNC/Manager"}
                            toText={
                                <FormattedMessage
                                    id="navbar.cnc.manager"
                                    defaultMessage="CNCManager"
                                />}
                            text={
                                <FormattedMessage
                                    id="carousel.manager.main"
                                    defaultMessage="The ideal software solution for diagnostics, data transfer and data backup"
                                />}
                        />
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}>
                        <CNCElement
                            imageSrc={getImage(this.props.lang).cncMachine.src}
                            imageAlt={getImage(this.props.lang).cncMachine.alt}
                            to={"/CNC/Service"}
                            toText={
                                <FormattedMessage
                                    id="navbar.cnc.service"
                                    defaultMessage="CNC-Service"
                                />}
                            text={<FormattedMessage
                                id="cnc.service"
                                defaultMessage="Repairs of machines of different manufacturers"
                            />}
                        />
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}>
                        <CNCElement
                            imageSrc={getImage(this.props.lang).steuerungenMain.src}
                            imageAlt={getImage(this.props.lang).steuerungenMain.alt}
                            to={"/CNC/Steuerungen"}
                            toText={
                                <FormattedMessage
                                    id="navbar.cnc.steuerungen"
                                    defaultMessage="Controller"
                                />}
                            text={<FormattedMessage
                                id="steuerungen9.5"
                                defaultMessage="PLC programming of almost all Siemens and Fanuc controls"
                            />}
                        />
                    </Grid>
                </Grid>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(CNC);