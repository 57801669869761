import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselElement from "../../../components/CarouselElement";
import MyCarousel from "../../../components/MyCarousel";
import getImage from "../../../res/imgs";
import {FormattedMessage} from "react-intl";

class CNCMonitoringCarousel extends Component {
    render() {
        return (
            <MyCarousel>
                <CarouselElement
                    imageSrc={getImage(this.props.lang).fanucCnc.src}
                    header={<FormattedMessage
                        id="carousel.monitoring1.header"
                        defaultMessage="Screenshot Fanuc"
                    />}
                    text={<FormattedMessage
                        id="carousel.monitoring1.main"
                        defaultMessage="Example image of a Fanuc diagnostic screen"
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).siemensCnc.src}
                    header={<FormattedMessage
                        id="carousel.monitoring2.header"
                        defaultMessage="Screenshot Siemens"
                    />}
                    text={<FormattedMessage
                        id="carousel.monitoring2.main"
                        defaultMessage="Example image of a Siemens diagnostic screen"
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).webCnc.src}
                    header={<FormattedMessage
                        id="carousel.monitoring3.header"
                        defaultMessage="Screenshot web application"
                    />}
                    text={<FormattedMessage
                        id="carousel.monitoring3.main"
                        defaultMessage="Example image of a diagnostic screen"
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
            </MyCarousel>
        );
    }
}

export default CNCMonitoringCarousel;