import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import {FormattedMessage} from "react-intl";

const styles = () => ({
    container: {},
    list: {
        listStyleType: 'square',
    }
})

class IT extends Component {

    componentDidMount() {
        document.title = 'IT-Support';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={
                <FormattedMessage
                    id="navbar.it"
                    defaultMessage="IT-Support"
                />
            }>
                <p>
                    <FormattedMessage
                        id="it.first"
                        defaultMessage="You don't have the capacity to expand or modernise your own IT department? Then our expertise is the ideal solution for you. We offer calculable and flexible support for your IT environment so that you can concentrate on your core business."
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="it.second"
                        defaultMessage="With us as your contact partner, your hardware and software problems can be analysed and solved. Through a precise inventory of your IT environment, we can offer you IT services tailored to your needs. For this purpose, we are available to you at any time by e-mail, telephone, remote maintenance or directly on site."
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="it.third"
                        defaultMessage="From the procurement and installation of hardware and software in physical and virtual IT environments to IT support - you are in good hands with us."
                    />
                </p>

                <p>
                    <FormattedMessage
                        id="it.ourOffer"
                        defaultMessage="Our tasks include:"
                    />
                </p>

                <ul className={classes.list}>
                    <li>
                        <FormattedMessage
                            id="it.list1"
                            defaultMessage="Planning and configuration of your IT systems"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="it.list2"
                            defaultMessage="Monitoring and maintenance of your IT infrastructure"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="it.list3"
                            defaultMessage="Digitisation of business processes"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="it.list4"
                            defaultMessage="Network installation and administration"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="it.list5"
                            defaultMessage="Support and installation of hardware and software"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="it.list6"
                            defaultMessage="Server and client support"
                        />
                    </li>
                </ul>
                <p>
                    <FormattedMessage
                        id="it.contact"
                        defaultMessage="Feel free to contact us. We will show you what support options we can offer your company."
                    />
                </p>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(IT);