import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../../components/MainPage";
import {FormattedMessage} from "react-intl";
import getImage from "../../../res/imgs";
import spacer from "../../../res/spacer";
import palette from "../../../res/palette";

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacer.bigger,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        maxWidth: '700px',
        width: '100%',
        border: palette.border.main,
    },
    list: {
        listStyleType: 'square',
    }
})

class Steuerungen extends Component {

    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'Steuerungen' : 'Controller';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={<FormattedMessage
                id="navbar.cnc.steuerungen"
                defaultMessage="Controller"
            />}>
                <Grid container className={classes.container}>
                    <Grid className={classes.grid}
                          item
                          xl={9}
                          lg={9}
                          sm={12}
                          xs={12}>
                        <ul className={classes.list}>
                            <li>
                                <FormattedMessage
                                    id="steuerungen1"
                                    defaultMessage="You want to send or receive data via network?"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="steuerungen2"
                                    defaultMessage="You want to expand your machine/control system?"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="steuerungen3"
                                    defaultMessage="You plan to integrate a robot?"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="steuerungen4"
                                    defaultMessage="Are you planning additional functions on your machine?"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="steuerungen5"
                                    defaultMessage="Do you want a message via mail in case of error messages?"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="steuerungen6"
                                    defaultMessage="Do you want to monitor your production data?"
                                />
                            </li>
                        </ul>

                        <p>
                            <FormattedMessage
                                id="steuerungen7"
                                defaultMessage="We are your competent partner."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="steuerungen8"
                                defaultMessage="No matter if Step7, Step5 or Fanuc Fladder."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="steuerungen9"
                                defaultMessage="PLC programming of almost all Siemens and Fanuc controls."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="question.ask"
                                defaultMessage="Feel free to contact us if you have any questions or interest. We will take care of your request as soon as possible."
                            />
                        </p>
                    </Grid>
                </Grid>
                <p className={classes.wrapper}>
                    <img className={classes.img} src={getImage(this.props.lang).steuerungenMain.src}
                         alt={getImage(this.props.lang).steuerungenMain.alt}/>
                </p>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(Steuerungen);