import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import spacer from "../../res/spacer";
import getImage from "../../res/imgs";
import {FormattedMessage} from "react-intl";
import typography from "../../res/typography";

const styles = () => ({
    container: {},
    grid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: spacer.borderRad,
        padding: spacer.medium,
    },
    center: {
        textAlign: 'center',
    },
    img: {
        maxWidth: spacer.refImage,
        maxHeight: '100px',
        padding: `0`,
    },
    myHeader: {
        paddingBottom: spacer.medium,
        fontWeight: 'bold',
        fontSize: typography.headerText,
    },
})

class References extends Component {

    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'Referenzen und Partner' : 'References and partners';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={
                <FormattedMessage
                    id="footer.ref"
                    defaultMessage="References"
                />
            }>
                <Grid
                    className={classes.container}
                    container
                >
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="https://chiron.de/" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).chiron.src}
                                     alt={getImage(this.props.lang).chiron.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>CHIRON Group SE</div>
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="https://www.fanuc.eu/de/de/cnc" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).fanuc.src}
                                     alt={getImage(this.props.lang).fanuc.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>FANUC Deutschland GmbH</div>
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="https://www.iwc.com/de/de/home.html" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).iwc.src}
                                     alt={getImage(this.props.lang).iwc.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>IWC Schaffhausen</div>
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="https://www.sram.com/de/sram" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).sram.src}
                                     alt={getImage(this.props.lang).sram.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>SRAM Deutschland GmbH</div>
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="https://www.stihl.de/" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).stihl.src}
                                     alt={getImage(this.props.lang).stihl.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>ANDREAS STIHL AG & Co.KG</div>
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="https://www.baumer.com/" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).baumer.src}
                                     alt={getImage(this.props.lang).baumer.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>Baumer Thalheim GmbH & Co.KG</div>
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="http://www.cnc-service-gmbh.de" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).cncServicesGmbh.src}
                                     alt={getImage(this.props.lang).cncServicesGmbh.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>CNC-Service GmbH</div>
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <h1 className={classes.center}>
                            <a href="https://www.egsautomatisierung.de/" target="_blank" rel="noreferrer noopener">
                                <img className={classes.img} src={getImage(this.props.lang).egs.src}
                                     alt={getImage(this.props.lang).egs.alt}/>
                            </a>
                        </h1>
                        <div className={classes.myHeader}>EGS Automatisierungstechnik GmbH</div>
                    </Grid>
                </Grid>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(References);