import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {IntlProvider} from 'react-intl';
import German from './lang/de.json';
import English from './lang/en.json';

const messages = {
    'de': German,
    'en': English
};

const language = navigator.language.split(/[-_]/)[0];

function saveToLocalStorage(lang) {
    if (localStorage.getItem('lang') === null) {
        localStorage.setItem('lang', lang);
    }
}

function getFromLocalStorage() {
    const lang = localStorage.getItem('lang')

    if (lang === null) {
        return language
    } else {
        return lang
    }
}

ReactDOM.render(
    <IntlProvider locale={getFromLocalStorage()} messages={messages[getFromLocalStorage()]}>
        {saveToLocalStorage(language)}
        <App lang={getFromLocalStorage()}/>
    </IntlProvider>,
    document.getElementById('root')
);
