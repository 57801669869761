import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import palette from "../res/palette";

const styles = () => ({
    grid: {
        paddingLeft: '24px',
    },
    wrapper: {
        border: palette.border.main,
        padding: '0 24px 24px 24px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'sticky',
        top: '100px',
    }
})

class QuickAccessBox extends Component {
    render() {
        const {classes} = this.props;

        return (
            <Grid
                item
                className={classes.grid}
                xl={3}
                lg={3}
                sm={12}
                xs={12}>
                <div className={classes.wrapper}>
                    <h2>
                        <FormattedMessage
                            id="quick.access"
                            defaultMessage="Quick access"
                        />
                    </h2>
                    {this.props.children}
                </div>
            </Grid>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(QuickAccessBox);