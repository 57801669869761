import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import spacer from "../res/spacer";
import palette from "../res/palette";

const styles = () => ({
    wrapper: {
        height: spacer.grad,
        background: palette.gradient,
        padding: 0,
        margin: 0,
        border: 0
    },
})

class HorizontalGradient extends Component {
    render() {
        const {classes} = this.props;

        return (
            <hr className={classes.wrapper}/>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(HorizontalGradient);