import './App.css'
import './components/NavBar/stylesNavBar.css'
import {Route, HashRouter as Router, Routes} from 'react-router-dom'
import NavBar from "./components/NavBar/NavBar"
import Footer from "./components/Footer/Footer"
import Home from "./views/Home/Home"
import About from "./views/About/About"
import CNC from "./views/CNC/CNC"
import IT from "./views/IT/IT"
import Contact from "./views/Contact/Contact"
import NotFound from "./views/NotFound/NotFound"
import React, {Component} from "react";
import References from "./views/References/References";
import Imprint from "./views/Imprint/Imprint";
import Terms from "./views/Terms/Terms";
import CNCManager from "./views/CNC/CNCManager/CNCManager";
import CNCMonitoring from "./views/CNC/CNCMonitoring/CNCMonitoring";
import CNCService from "./views/CNC/CNCService/CNCService";
import Steuerungen from "./views/CNC/Steuerungen/Steuerungen";
import ContactOrder from "./views/Contact/Order/ContactOrder";
import ContactDownloads from "./views/Contact/Downloads/ContactDownloads";
import CNCManagerFAQ from "./views/CNC/CNCManager/CNCManagerFAQ";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    render() {
        return (
            <Router basename={"/"}>
                <NavBar isMobile={this.state.width} lang={this.props.lang}/>
                <Routes>
                    <Route path="/" element={<Home isMobile={this.state.width} lang={this.props.lang}/>}/>
                    <Route path="/Home" element={<Home isMobile={this.state.width} lang={this.props.lang}/>}/>
                    <Route path="/About" element={<About lang={this.props.lang}/>}/>
                    <Route path="/CNC" element={<CNC lang={this.props.lang}/>}/>
                    <Route path="/CNC/Manager" element={<CNCManager isMobile={this.state.width} lang={this.props.lang}/>}/>
                    <Route path="/CNC/Manager/FAQ" element={<CNCManagerFAQ lang={this.props.lang}/>}/>
                    <Route path="/CNC/Monitoring" element={<CNCMonitoring isMobile={this.state.width} lang={this.props.lang}/>}/>
                    <Route path="/CNC/Service" element={<CNCService lang={this.props.lang}/>}/>
                    <Route path="/CNC/Steuerungen" element={<Steuerungen lang={this.props.lang}/>}/>
                    <Route path="/IT" element={<IT lang={this.props.lang}/>}/>
                    <Route path="/Contact" element={<Contact lang={this.props.lang}/>}/>
                    <Route path="/Contact/Order" element={<ContactOrder lang={this.props.lang}/>}/>
                    <Route path="/Contact/Downloads" element={<ContactDownloads lang={this.props.lang}/>}/>
                    <Route path="/References" element={<References lang={this.props.lang}/>}/>
                    <Route path="/Imprint" element={<Imprint lang={this.props.lang}/>}/>
                    <Route path="/Terms" element={<Terms lang={this.props.lang}/>}/>
                    <Route path="*" element={<NotFound lang={this.props.lang}/>}/>
                </Routes>
                <Footer lang={this.props.lang}/>
            </Router>

        );
    }
}

export default App;
