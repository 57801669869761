import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import spacer from "../res/spacer";
import typography from "../res/typography";
import MyButton from "./Basics/MyButton";
import palette from "../res/palette";

const styles = () => ({
    carouselDiv: {
        maxWidth: `${spacer.pageWidth}`,
        maxHeight: `${spacer.carouselImage}`,
    },
    carouselDivMobile: {
        maxWidth: `${spacer.pageWidth}`,
        maxHeight: `${spacer.carouselImageMobile}`,
    },
    carouselDivMobileSmall: {
        maxWidth: `${spacer.pageWidth}`,
        maxHeight: `${spacer.carouselImageMobileSmall}`,
    },
    carouselDivMobileExtraSmall: {
        maxWidth: `${spacer.pageWidth}`,
        maxHeight: `${spacer.carouselImageMobileExtraSmall}`,
    },
    carouselDivMobileExtraExtraSmall: {
        maxWidth: `${spacer.pageWidth}`,
        maxHeight: `${spacer.carouselImageMobileExtraExtraSmall}`,
    },
    carouselDivMobileExtraExtraExtraSmall: {
        maxWidth: `${spacer.pageWidth}`,
        maxHeight: `${spacer.carouselImageMobileExtraExtraExtraSmall}`,
    },
    images: {
        '&:hover': {
            '-webkit-filter': 'blur(5px)', /* Safari 6.0 - 9.0 */
            filter: 'blur(5px)',
        },
    },
    legend: {
        position: "absolute",
        width: '480px',
        left: "10%",
        top: "45%",
        backgroundColor: 'rgba(232, 245, 255, 0.9)',
        border: palette.border.main,
        padding: spacer.medium,
        textAlign: 'left',
        fontSize: typography.iconText,
        lineHeight: '30px',
    },
    myHeader: {
        paddingBottom: spacer.medium,
        fontWeight: 'bold',
        fontSize: typography.headerText,
    }
})

class CarouselElement extends Component {
    render() {
        const {classes} = this.props;

        return (
            <div
                className={this.props.isMobile ? this.props.size < 870 ? this.props.size < 770 ? this.props.size < 660 ? this.props.size < 550 ? classes.carouselDivMobileExtraExtraExtraSmall : classes.carouselDivMobileExtraExtraSmall : classes.carouselDivMobileExtraSmall : classes.carouselDivMobileSmall : classes.carouselDivMobile : classes.carouselDiv}>
                <img className={classes.images} src={this.props.imageSrc} alt={this.props.header}/>
                {this.props.isMobile ? null : <div className={classes.legend}>
                    <div className={classes.myHeader}>{this.props.header}</div>
                    {this.props.text}
                    {this.props.to === '' ? null :
                        <MyButton
                            to={this.props.to}
                            toText={this.props.toText}
                        />
                    }
                </div>}
            </div>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(CarouselElement);