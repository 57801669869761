import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselElement from "../../../components/CarouselElement";
import MyCarousel from "../../../components/MyCarousel";
import getImage from "../../../res/imgs";
import {FormattedMessage} from "react-intl";

class CNCManagerCarousel extends Component {
    render() {
        return (
            <MyCarousel>
                <CarouselElement
                    imageSrc={getImage(this.props.lang).mainPage232.src}
                    header={<FormattedMessage
                        id="carousel.manager1.header"
                        defaultMessage="RS232 data transmission"
                    />}
                    text={<FormattedMessage
                        id="carousel.manager1.main"
                        defaultMessage="You can exchange data between the CNC control and the PC via RS232. Every imaginable transmission parameter can be adapted to the CNC control."
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).mainPage.src}
                    header={<FormattedMessage
                        id="carousel.manager2.header"
                        defaultMessage="Network data transmission"
                        />}
                    text={<FormattedMessage
                        id="carousel.manager2.main"
                        defaultMessage="With modern Fanuc controllers an easy to use data exchange via network is possible."
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).alleFenster.src}
                    header={<FormattedMessage
                        id="carousel.manager3.header"
                        defaultMessage="Numerous functions"
                        />}
                    text={<FormattedMessage
                        id="carousel.manager3.main"
                        defaultMessage="You can check the status of a Siemens or Fanuc controller (with appropriate hardware) and transfer data via RS232 or network (Fanuc only). You can also edit data and compare them with each other. All functions are possible at the same time."
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).statusPage.src}
                    header={<FormattedMessage
                        id="carousel.manager4.header"
                        defaultMessage="Status Fanuc"
                    />}
                    text={<FormattedMessage
                        id="carousel.manager4.main"
                        defaultMessage="You can check the status of your Fanuc controller and record signals at any time."
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
                <CarouselElement
                    imageSrc={getImage(this.props.lang).statusPageSiemens.src}
                    header={<FormattedMessage
                        id="carousel.manager5.header"
                        defaultMessage="Status Siemens"
                    />}
                    text={<FormattedMessage
                        id="carousel.manager5.main"
                        defaultMessage="You can check the status of your Fanuc controller and record signals at any time."
                    />}
                    to={''}
                    isMobile={this.props.isMobile}
                    size={this.props.size}
                />
            </MyCarousel>
        );
    }
}

export default CNCManagerCarousel;