const white = '#FFFFFF'
const black = '#000000'
const skyblue = '#87CEEB'

export default {
    black,
    white,
    skyblue,
    primary: {
        main: 'rgb(49, 134, 165)',
        secondary: 'yellow',
        dark: '#0001F8',
        contrastText: white,
        skyblue: skyblue,
    },
    red: {
        main: '#FF0000',
        alert: '#721c24',
        alertInvert: '#f5c6cb',
    },
    green: {
        main: '#00AB00',
        success: '#155724',
        successInvert: '#d4edda'
    },
    yellow: {
        main: '#FFFF00'
    },
    grey: {
        main: '#E8F5FF'
    },
    line: {
        main: 'rgba(255, 255, 255, .2)',
    },
    gradient: `linear-gradient(to right, ${black}, ${skyblue})`,
    gradientVertical: `linear-gradient(to bottom, ${black}, ${skyblue})`,
    border: {
        main: '1px black solid'
    }
}
