import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import ContactElement from "../../components/ContactElement";
import spacer from "../../res/spacer";
import {FormattedMessage} from "react-intl";
import ContactBlock from "../../components/ContactBlock";

const styles = () => ({
    container: {
        paddingTop: spacer.medium,
    }
})

class Contact extends Component {

    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'Kontakt' : 'Contact';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={
                <FormattedMessage
                    id="navbar.contact"
                    defaultMessage="Contact"
                />
            }>
                <FormattedMessage
                    id="imprint.contactHere"
                    defaultMessage="If you are interested you can contact us at:"
                />
                <ContactBlock
                    lang={this.props.lang}
                />
                <Grid
                    container
                    className={classes.container}
                >
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}>
                        <ContactElement
                            to={"/Contact/Order"}
                            toText={
                                <FormattedMessage
                                    id="navbar.contact.order"
                                    defaultMessage="Order"
                                />
                            }
                            text={'Alle Informationen zu unseren Produkten, Dienstleistungen und dem Bestellprozess'}
                        />
                    </Grid>
                    <Grid
                        className={classes.grid}
                        item
                        xl={6}
                        lg={6}
                        sm={12}
                        xs={12}>
                        <ContactElement
                            to={"/Contact/Downloads"}
                            toText={
                                <FormattedMessage
                                    id="navbar.contact.downloads"
                                    defaultMessage="Downloads"
                                />
                            }
                            text={'Alle Informationen und Dokumentationen zu unseren Produkten und Dienstleistungen'}
                        />
                    </Grid>
                </Grid>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(Contact);