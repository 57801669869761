export default {
    //Standard
    mini: '4px',
    small: '8px',
    medium: '16px',
    big: '20px',
    evenBig: '30px',
    bigger: '40px',

    //Gradient height
    grad: '2px',

    //Footer
    footerHeight: '10rem',

    //Navbar height
    navBarHeight: '3rem',

    //Mainpage Padding
    pageWidth: '1240px',

    //Border Radius
    borderRad: '3px',

    //Images
    refImage: '230px',
    carouselImage: '600px',
    carouselImageMobile: '450px',
    carouselImageMobileSmall: '400px',
    carouselImageMobileExtraSmall: '350px',
    carouselImageMobileExtraExtraSmall: '233px',
    carouselImageMobileExtraExtraExtraSmall: '150px'
}
