import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import {FormattedMessage} from "react-intl";
import MyTimeline from "./MyTimeline";

const styles = () => ({
    container: {},
    padTop: {
        paddingTop: '24px'
    }
})

class About extends Component {

    componentDidMount() {
        document.title = 'Über uns';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={<FormattedMessage
                id="navbar.about"
                defaultMessage="About us"
            />
            }>
                <h2>
                    <FormattedMessage
                        id="about0"
                        defaultMessage="Who are we?"
                    />
                </h2>
                <p>
                    <FormattedMessage
                        id="about2"
                        defaultMessage="It all started in 1983 when I, Thomas Lütje, was employed as an electrician in the electrical assembly department for CNC-controlled machining centres at CHIRON. There I took on worldwide assignments in the commissioning and new installation of CNC machines. I developed the idea of the CNCManager during my assignments in the USA in order to be able to offer an ideal software solution for diagnosis, data transfer and data backup. In order to be able to implement this project, I started my self-employment in the main field of electronics and PLC programming in parallel from 2000. Since 2009, I have been working full-time for Lütje Steuerungstechnik GmbH & Co. KG."
                    />
                </p>
                <h2 className={classes.padTop}>
                    <FormattedMessage
                        id="about.milestones"
                        defaultMessage="Milestones"
                    />
                </h2>
                <MyTimeline
                    lang={this.props.lang}
                />
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(About);