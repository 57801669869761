import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import fonts from "../../res/fonts";
import palette from "../../res/palette";


const StyledLink = styled(Link)`
    text-decoration: none;
    display: inline-block;
    background-image: linear-gradient(to right, ${palette.black}, ${palette.skyblue});
    background-size: 0% var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.3s;
    background-position: 0 calc(100% - var(--bottom-distance));
    --border-color: ${palette.skyblue};
    --border-width: 5px;
    --bottom-distance: -3px;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: ${fonts.nColor};
    }
    
    &:hover {
      background-size: 100% var(--border-width);
    }
`;

//from left:   background-position: 0 calc(100% - var(--bottom-distance));
//from center:    background-position: 50% calc(100% - var(--bottom-distance));
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => <StyledLink {...props} />;