import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import './styles.scss'
import getImage from "../../res/imgs";
import palette from "../../res/palette";

const styles = () => ({
    img: {
        maxWidth: '100%',
        marginBottom: '14px',
        border: palette.border.main
    },
})

const itemsD = [
    {
        date: "1983",
        cardTitle: "Tuttlingen, DE",
        cardSubtitle: "Elektriker für Installationen der CNC-Maschinen, später Inbetriebnahme von Maschinen bei CHIRON Group SE"
    },
    {
        date: "1991",
        cardTitle: "Tuttlingen, DE",
        cardSubtitle: "Servicetechniker mit Schwerpunkt Elektronik, Steuerungs- und Antriebstechnik bei CHIRON Group SE (weltweiter Einsatz) "
    },
    {
        date: "1997",
        cardTitle: "North Carolina, USA",
        cardSubtitle: "Projektleiter der Elektronik für Installationen, Inbetriebnahmen und Instandhaltungen zweier Großprojekte bei CHIRON America Inc. (PBR und Chrysler)"
    },
    {
        date: "2000",
        cardTitle: "Tuttlingen, DE",
        cardSubtitle: "Abteilungsadministrator im Service, Ferndiagnose Administrator und Unterstützung der Servicetechniker bei Problemfällen bei CHIRON Group SE"
    },
    {
        date: "2007",
        cardTitle: "Stockach, DE",
        cardSubtitle: "Selbstständige Tätigkeit im Bereich CNC-Maschinenservice in den Fachrichtungen Elektronik, Steuerungs- und Antriebstechnik",
    },
    {
        date: "2009",
        cardTitle: "Stockach, DE",
        cardSubtitle: "Gründung der Lütje Steuerungstechnik GmbH & Co. KG",
        img: "halle"
    },
]

const itemsE = [
    {
        date: "1983",
        cardTitle: "Tuttlingen, GER",
        cardSubtitle: "Electrician for installations of CNC machines, later commissioning of machines at CHIRON Group SE"
    },
    {
        date: "1991",
        cardTitle: "Tuttlingen, GER",
        cardSubtitle: "Service technician with focus on electronics, control and drive technology at CHIRON Group SE (worldwide deployment)"
    },
    {
        date: "1997",
        cardTitle: "North Carolina, USA",
        cardSubtitle: "Electronic Project Manager for installations, commissioning and maintenance of two major projects at CHIRON America Inc. (PBR and Chrysler)"
    },
    {
        date: "2000",
        cardTitle: "Tuttlingen, GER",
        cardSubtitle: "Department administrator in service, remote diagnosis administrator and support for service technicians in problem cases at CHIRON Group SE)"
    },
    {
        date: "2007",
        cardTitle: "Stockach, GER",
        cardSubtitle: "Independent activity in the area of CNC machine service in the fields of electronics, control and drive technology",
    },
    {
        date: "2009",
        cardTitle: "Stockach, GER",
        cardSubtitle: "Foundation of Lütje Steuerungstechnik GmbH & Co. KG",
        img: "halle"
    },
]

class MyTimeline extends Component {
    returnList(lang) {
        if (lang === 'de') {
            return itemsD
        } else {
            return itemsE
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <section id="cd-timeline" className="cd-container">
                {this.returnList(this.props.lang).map((item, i) => {
                    return <div key={i} className="cd-timeline-block">
                        <div className="cd-timeline-img cd-movie">
                        </div>

                        <div className="cd-timeline-content">
                            <h2>{item.cardTitle}</h2>
                            <p>{item.cardSubtitle}</p>
                            {item.img ?
                                <img src={getImage(this.props.lang).halle.src} alt={getImage(this.props.lang).halle.alt}
                                     className={classes.img}/>
                                : null}
                            <span className="cd-date">{item.date}</span>
                        </div>
                    </div>
                })}
            </section>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(MyTimeline);