import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {withStyles} from "@material-ui/core";
import spacer from "../../res/spacer";
import palette from "../../res/palette";
import StickyNavBar from "./StickyNavBar"
import fonts from "../../res/fonts";
import StyledLink from "./StyledLink";
import HorizontalGradient from "../HorizontalGradient";
import MyDrawer from "./MyDrawer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {FormattedMessage} from "react-intl";
import getImage from "../../res/imgs";

const styles = () => ({
    container: {
        fontFamily: fonts.nFamily,
        fontSize: fonts.nSize,
        color: fonts.nColor,
        fontStyle: fonts.nStyle,
        maxHeight: spacer.footerHeight + spacer.navBarHeight,
    },
    center: {
        textAlign: 'center',
    },
    img: {
        width: '880px',
        padding: `${spacer.big} 0`
    },
    navContainer: {
        backgroundColor: palette.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nav: {
        listStyleType: 'none',
        width: spacer.pageWidth,
        maxWidth: spacer.pageWidth,
        display: 'flex',
        justifyContent: 'space-around',
        margin: 0,
        padding: 0,
    },
    navElement: {
        padding: `${spacer.small} ${spacer.big}`,
        border: '1px solid white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    navDropDown: {
        position: 'absolute',
        display: 'block',
        minWidth: '180px',
        backgroundColor: palette.white,
        margin: 0,
        padding: `0`,
        top: '76%',
        zIndex: 1,
        border: palette.border.main,
    },
    inLine: {
        display: 'flex',
        alignItems: 'center',
    },
    iconInLine: {
        paddingLeft: '5px',
    }
})

class NavBar extends Component {
    state = {
        showBox: false,
        dropDownOpen: false,
        showBoxContact: false,
        dropDownOpenContact: false,
    };

    reset = () => {
        this.setState({showBox: false})
        this.setState({dropDownOpen: false})
        this.setState({showBoxContact: false})
        this.setState({dropDownOpenContact: false})
    };

    handleBoxOpen = () => {
        this.setState({showBox: true})
    };

    handleBoxClose = () => {
        if (!this.state.dropDownOpen)
            this.setState({showBox: false})
    };

    handleDropDownOpen = () => {
        this.setState({dropDownOpen: true})
    };

    handleDropDownClose = () => {
        this.setState({dropDownOpen: false})
    };

    handleBoxOpenContact = () => {
        this.setState({showBoxContact: true})
    };

    handleBoxCloseContact = () => {
        if (!this.state.dropDownOpenContact)
            this.setState({showBoxContact: false})
    };

    handleDropDownOpenContact = () => {
        this.setState({dropDownOpenContact: true})
    };

    handleDropDownCloseContact = () => {
        this.setState({dropDownOpenContact: false})
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.container}>
                {this.props.isMobile > 1080 ?
                    <div><NavLink to={"/"}>
                        <h1 className={classes.center}>
                            <img
                                className={classes.img} src={getImage(this.props.lang).header.src}
                                alt={getImage(this.props.lang).header.alt}/>
                        </h1>
                    </NavLink>
                        <StickyNavBar props={
                            <div>
                                <HorizontalGradient/>
                                <div className={classes.navContainer}>
                                    <ul className={classes.nav}>
                                        <li className={classes.navElement}><StyledLink to="/Home">
                                            <FormattedMessage
                                                id="navbar.home"
                                                defaultMessage="Home"
                                            />
                                        </StyledLink>
                                        </li>
                                        <li className={classes.navElement}>
                                            <StyledLink to="/About">
                                                <FormattedMessage
                                                    id="navbar.about"
                                                    defaultMessage="About us"
                                                />
                                            </StyledLink>
                                        </li>
                                        <li className={classes.navElement} onClick={this.reset}>
                                            <StyledLink
                                                onMouseEnter={this.handleBoxOpen}
                                                onMouseLeave={this.handleBoxClose}
                                                to="/CNC">
                                                <div className={classes.inLine}>
                                                    <FormattedMessage
                                                        id="navbar.cnc"
                                                        defaultMessage="CNC-Solutions"
                                                    />{!this.state.showBox && !this.state.dropDownOpen ?
                                                    <ExpandMoreIcon className={classes.iconInLine}/> :
                                                    <ExpandLessIcon className={classes.iconInLine}/>}
                                                </div>
                                            </StyledLink>
                                            {!this.state.showBox && !this.state.dropDownOpen ? null :
                                                <ul
                                                    className={classes.navDropDown}
                                                    onMouseEnter={this.handleDropDownOpen}
                                                    onMouseLeave={this.handleDropDownClose}>
                                                    <li className={classes.navElement} onClick={this.reset}><StyledLink
                                                        to="/CNC/Monitoring">
                                                        <FormattedMessage
                                                            id="navbar.cnc.monitoring"
                                                            defaultMessage="CNCMonitoring"
                                                        />
                                                    </StyledLink></li>
                                                    <li className={classes.navElement} onClick={this.reset}><StyledLink
                                                        to="/CNC/Manager">
                                                        <FormattedMessage
                                                            id="navbar.cnc.manager"
                                                            defaultMessage="CNCManager"
                                                        />
                                                    </StyledLink></li>
                                                    <li className={classes.navElement} onClick={this.reset}><StyledLink
                                                        to="/CNC/Service">
                                                        <FormattedMessage
                                                            id="navbar.cnc.service"
                                                            defaultMessage="CNC-Service"
                                                        />
                                                    </StyledLink></li>
                                                    <li className={classes.navElement} onClick={this.reset}><StyledLink
                                                        to="/CNC/Steuerungen">
                                                        <FormattedMessage
                                                            id="navbar.cnc.steuerungen"
                                                            defaultMessage="Controller"
                                                        />
                                                    </StyledLink></li>
                                                </ul>}
                                        </li>
                                        <li className={classes.navElement}><StyledLink to="/IT">
                                            <FormattedMessage
                                                id="navbar.it"
                                                defaultMessage="IT-Support"
                                            />
                                        </StyledLink>
                                        </li>
                                        <li className={classes.navElement} onClick={this.reset}>
                                            <StyledLink
                                                onMouseEnter={this.handleBoxOpenContact}
                                                onMouseLeave={this.handleBoxCloseContact}
                                                to="/Contact">
                                                <div className={classes.inLine}>
                                                    <FormattedMessage
                                                        id="navbar.contact"
                                                        defaultMessage="Contact"
                                                    /> {!this.state.showBoxContact && !this.state.dropDownOpenContact ?
                                                    <ExpandMoreIcon className={classes.iconInLine}/> :
                                                    <ExpandLessIcon className={classes.iconInLine}/>}
                                                </div>
                                            </StyledLink>
                                            {!this.state.showBoxContact && !this.state.dropDownOpenContact ? null :
                                                <ul
                                                    className={classes.navDropDown}
                                                    onMouseEnter={this.handleDropDownOpenContact}
                                                    onMouseLeave={this.handleDropDownCloseContact}>
                                                    <li className={classes.navElement} onClick={this.reset}><StyledLink
                                                        to="/Contact/Order">
                                                        <FormattedMessage
                                                            id="navbar.contact.order"
                                                            defaultMessage="Orderform"
                                                        />
                                                    </StyledLink></li>
                                                    <li className={classes.navElement} onClick={this.reset}><StyledLink
                                                        to="/Contact/Downloads">
                                                        <FormattedMessage
                                                            id="navbar.contact.downloads"
                                                            defaultMessage="Downloads"
                                                        />
                                                    </StyledLink></li>
                                                </ul>}
                                        </li>
                                    </ul>
                                </div>
                                <HorizontalGradient/>
                            </div>
                        }>
                        </StickyNavBar>
                    </div> :
                    <MyDrawer isMobile={this.props.isMobile} lang={this.props.lang}/>
                }
            </div>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(NavBar);