const german = {
    header: {
        src: "/images/cnc_logo_g.jpg",
        alt: "Lütje Steuerungstechnik GmbH & Co.KG",
    },
    halle: {
       src: "/images/halle.jpg",
       alt: "Halle"
    },
    //CNC Manager
    alleFenster: {
        src: "/images/cncManager/2/g/AlleFenster_g.PNG",
        alt: "Alle Fenster",
    },
    mainPage: {
        src: "/images/cncManager/2/g/MainPage_g.JPG",
        alt: "MainPage",
    },
    mainPage232: {
        src: "/images/cncManager/2/g/MainPageRs232_g.JPG",
        alt: "MainPage RS232",
    },
    statusPage: {
        src: "/images/cncManager/2/g/StatusPage_g.JPG",
        alt: "StatusPage",
    },
    statusPageSiemens: {
        src: "/images/cncManager/2/g/StatusPageSiemens_g.JPG",
        alt: "StatusPage Siemens",
    },
    //CNC Monitoring
    fanucCnc: {
        src: "/images/cncMonitoring/FanucCNC.PNG",
        alt: "Screenshot Fanuc",
    },
    siemensCnc: {
        src: "/images/cncMonitoring/SiemensCNC.PNG",
        alt: "Screenshot Siemens",
    },
    webCnc: {
        src: "/images/cncMonitoring/WEBCNC.PNG",
        alt: "Screenshot Web",
    },
    cncMonitoring: {
        src: "/images/cncMonitoring/cncMonitoring_screenshot.png",
        alt: "Screenshot Siemens",
    },
    monitoring: {
        src: "/images/cncMonitoring/monitoring.PNG",
        alt: "Screenshot Web",
    },
    //CNC Service
    zertifikat: {
        src: "/images/cncService/Chiron_Zertifikat.jpg",
        alt: "Chiron Zertifikat",
    },
    cncMachine: {
        src: "/images/cncService/cncService.jpg",
        alt: "CNC-Schaltschrank",
    },
    //IT Service
    itMain: {
        src: "/images/itServices/itServiceMain.jpg",
        alt: "Chiron Zertifikat",
    },
    //Referenzen
    baumer: {
        src: "/images/references/baumer_logo.jpg",
        alt: "Baumer Firmenlogo",
    },
    chiron: {
        src: "/images/references/chiron_logo.jpg",
        alt: "Chiron Firmenlogo",
    },
    cncServicesGmbh: {
        src: "/images/references/cncServicesGmbh.png",
        alt: "CNC Services GmbH Firmenlogo",
    },
    egs: {
        src: "/images/references/egs_logo.jpg",
        alt: "EGS Firmenlogo",
    },
    fanuc: {
        src: "/images/references/fanuc_logo.jpg",
        alt: "Fanuc Firmenlogo",
    },
    iwc: {
        src: "/images/references/iwc_logo.jpg",
        alt: "IWC Firmenlogo",
    },
    sram: {
        src: "/images/references/sram_logo.jpg",
        alt: "SRAM Firmenlogo",
    },
    stihl: {
        src: "/images/references/stihl_logo.jpg",
        alt: "Stihl Firmenlogo",
    },
    //Steuerungen
    steuerungenMain: {
        src: "/images/steuerungen/Steuerungen_main.png",
        alt: "Steuerungen"
    },
}

const english = {
    header: {
        src: "/images/cnc_logo_e.jpg",
        alt: "Lütje Steuerungstechnik GmbH & Co.KG",
    },
    halle: {
        src: "/images/halle.jpg",
        alt: "Halle"
    },
    //CNC Manager
    alleFenster: {
        src: "/images/cncManager/2/e/AlleFenster_e.PNG",
        alt: "Alle Fenster",
    },
    mainPage: {
        src: "/images/cncManager/2/e/MainPage_e.JPG",
        alt: "MainPage",
    },
    mainPage232: {
        src: "/images/cncManager/2/e/MainPageRs232_e.JPG",
        alt: "MainPage RS232",
    },
    statusPage: {
        src: "/images/cncManager/2/e/StatusPage_e.JPG",
        alt: "StatusPage",
    },
    statusPageSiemens: {
        src: "/images/cncManager/2/e/StatusPageSiemens_e.JPG",
        alt: "StatusPage Siemens",
    },
    //CNC Monitoring
    fanucCnc: {
        src: "/images/cncMonitoring/FanucCNC.PNG",
        alt: "Screenshot Fanuc",
    },
    siemensCnc: {
        src: "/images/cncMonitoring/SiemensCNC.PNG",
        alt: "Screenshot Siemens",
    },
    webCnc: {
        src: "/images/cncMonitoring/WEBCNC.PNG",
        alt: "Screenshot Web",
    },
    cncMonitoring: {
        src: "/images/cncMonitoring/cncMonitoring_screenshot.png",
        alt: "Screenshot Siemens",
    },
    monitoring: {
        src: "/images/cncMonitoring/monitoring.PNG",
        alt: "Screenshot Web",
    },
    //CNC Service
    zertifikat: {
        src: "/images/cncService/Chiron_Zertifikat.jpg",
        alt: "Chiron Certificate",
    },
    //IT Service
    itMain: {
        src: "/images/itServices/itServiceMain.jpg",
        alt: "IT Service",
    },
    cncMachine: {
        src: "/images/cncService/cncService.jpg",
        alt: "CNC cabinet",
    },
    //Referenzen
    baumer: {
        src: "/images/references/baumer_logo.jpg",
        alt: "Baumer Firmenlogo",
    },
    chiron: {
        src: "/images/references/chiron_logo.jpg",
        alt: "Chiron Firmenlogo",
    },
    cncServicesGmbh: {
        src: "/images/references/cncServicesGmbh.png",
        alt: "CNC Services GmbH Firmenlogo",
    },
    egs: {
        src: "/images/references/egs_logo.jpg",
        alt: "EGS Firmenlogo",
    },
    emminger: {
        src: "/images/references/emminger_logo.gif",
        alt: "Emminger Firmenlogo",
    },
    fanuc: {
        src: "/images/references/fanuc_logo.jpg",
        alt: "Fanuc Firmenlogo",
    },
    iwc: {
        src: "/images/references/iwc_logo.jpg",
        alt: "IWC Firmenlogo",
    },
    sram: {
        src: "/images/references/sram_logo.jpg",
        alt: "SRAM Firmenlogo",
    },
    stihl: {
        src: "/images/references/stihl_logo.jpg",
        alt: "Stihl Firmenlogo",
    },
    //Steuerungen
    steuerungenMain: {
        src: "/images/steuerungen/Steuerungen_main.png",
        alt: "Steuerungen"
    },
}

export default function getImage(lang) {
    if (lang === 'de') {
        return german
    } else {
        return english
    }
}