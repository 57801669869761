import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import {FormattedMessage} from "react-intl";
import StyledLink from "../../components/NavBar/StyledLink";

const styles = () => ({
    container: {},
    white: {
        color: '100%',
        fontStyle: 'italic',
    },
})

class NotFound extends Component {

    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'Seite nicht gefunden' : 'Page not found';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={<FormattedMessage
                id="navbar.404"
                defaultMessage="Not found"
            />}>
                <FormattedMessage
                    id="backToHome"
                    defaultMessage="Back to"
                />
                <StyledLink
                    className={classes.white}
                    to={"/Home"}>
                    <FormattedMessage
                        id="navbar.home"
                        defaultMessage="Home"
                    />
                </StyledLink>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(NotFound);