import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import MainPage from "../../../components/MainPage";
import spacer from "../../../res/spacer";
import {FormattedMessage} from "react-intl";
import CNCManagerFAQElement from "./CNCManagerFAQElement";

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    grid: {},
    wrapper: {
        padding: spacer.small,
    },
    img: {
        maxWidth: '384px',
    }
})

class CNCManagerFAQ extends Component {

    componentDidMount() {
        document.title = 'CNCManager FAQ';
        window.scrollTo(0, 0)
    }

    render() {
        return (
            //TODO: Add FA
            <MainPage title={<FormattedMessage
                id="navbar.cnc.manager.faq"
                defaultMessage="Frequently asked questions"
            />}>
                <CNCManagerFAQElement
                    lang={this.props.lang}
                />
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(CNCManagerFAQ);