const german = {
    cncInfo: {
        src: "https://www.tluetje.de/files/cncService/Informationsblatt_g.pdf",
        alt: "Informationsblatt",
    },
    cncManager1: {
        src: "https://www.tluetje.de/files/cncService/cncManager/1/dokumentation_deutsch.pdf",
        alt: "Dokumentation",
    },
    cncManager1Testversion: {
        src: "https://www.tluetje.de/data/cncwin/setupw2k/CNCsetup.zip",
        alt: "Testversion",
    },
    cncManager2: {
        src: "https://www.tluetje.de/files/cncService/cncManager/2/doku_g.pdf",
        alt: "Dokumentation",
    },
    cncManager2Testversion: {
        src: "https://www.tluetje.de/data/cncmanag_2/CNC2setup.zip",
        alt: "Testversion",
    },
    cncMonitoring: {
        src: "https://www.tluetje.de/files/cncService/cncMonitoring/dokuMonitoringSW_g.pdf",
        alt: "Dokumentation",
    },
    cncMonitoringTestversion: {
        src: "https://www.tluetje.de/data/cncmonitoring/CNCMonitoringsetup.7z",
        alt: "Testversion",
    },
    emailServer: {
        src: "https://www.tluetje.de/files/cncService/cncMonitoring/dokuEmail_g.pdf",
        alt: "Dokumentation",
    },
    agb: {
        src: "https://www.tluetje.de/files/geschaeftsbedingungen_g.pdf",
        alt: "Allgemeine Geschäftsbedingungen",
        short: "AGB",
    },
    order: {
        src: "https://www.tluetje.de/files/order/orderForm_g.pdf",
        alt: "Bestellformular",
    },
    price: {
        src: "https://www.tluetje.de/files/order/price_g.pdf",
        alt: "Preisliste",
    },
    spareParts: {
        src: "https://www.tluetje.de/files/cncService/spareparts_g.pdf",
        alt: "Ersatzteile",
    },
}

const english = {
    cncInfo: {
        src: "https://www.tluetje.de/files/cncService/Informationsblatt_e.pdf",
        alt: "Info Sheet",
    },
    cncManager1: {
        src: "https://www.tluetje.de/files/cncService/cncManager/1/dokumentation_deutsch.pdf",
        alt: "Documentation",
    },
    cncManager1Testversion: {
        src: "https://www.tluetje.de/data/cncwin/setupw2k/CNCsetup.zip",
        alt: "Testversion",
    },
    cncManager2: {
        src: "https://www.tluetje.de/files/cncService/cncManager/2/doku_e.pdf",
        alt: "Documentation",
    },
    cncManager2Testversion: {
        src: "https://www.tluetje.de/data/cncmanag_2/CNC2setup.zip",
        alt: "Testversion",
    },
    cncMonitoring: {
        src: "https://www.tluetje.de/files/cncService/cncMonitoring/dokuMonitoringSW_e.pdf",
        alt: "Documentation",
    },
    cncMonitoringTestversion: {
        src: "https://www.tluetje.de/data/cncmonitoring/CNCMonitoringsetup.7z",
        alt: "Testversion",
    },
    emailServer: {
        src: "https://www.tluetje.de/files/cncService/cncMonitoring/dokuEmail_e.pdf",
        alt: "Documentation",
    },
    agb: {
        src: "https://www.tluetje.de/files/geschaeftsbedingungen_e.pdf",
        alt: "General Terms & Conditions",
        short: "GTC",
    },
    order: {
        src: "https://www.tluetje.de/files/order/orderForm_e.pdf",
        alt: "Orderform",
    },
    price: {
        src: "https://www.tluetje.de/files/order/price_e.pdf",
        alt: "Price list",
    },
    spareParts: {
        src: "https://www.tluetje.de/files/cncService/spareparts_e.pdf",
        alt: "Spare parts",
    },
}

export default function getDoc(lang) {
    if (lang === 'de') {
        return german
    } else {
        return english
    }
}