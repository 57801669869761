import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../../components/MainPage";
import {FormattedMessage} from "react-intl";
import QuickAccessBox from "../../../components/QuickAccessBox";
import MyIconButton from "../../../components/Basics/MyIconButton";
import getDoc from "../../../res/docs";
import {OpenInNew} from "@material-ui/icons";
import spacer from "../../../res/spacer";
import ContactBlock from "../../../components/ContactBlock";

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacer.bigger,
    },
    grid: {},
    language: {
        textDecoration: 'none',
        color: 'black',
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    padTop: {
        paddingTop: '24px'
    },
    table: {
        width: '80%',
    },
    tdStyle: {
        textAlign: 'left',
        height: '35px'
    },
    tdStyleRight: {
        width: '100px',
        textAlign: 'right',
    }
})

class ContactOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: {},
        };
    }

    getArticle() {
        fetch('/data/articles.json')
            .then(res => {
                return res.json();
            })
            .then(data => {
                this.setState({
                    articles: data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'Bestellformular' : 'Order';
        window.scrollTo(0, 0)
        this.getArticle()
    }

    render() {
        const {classes} = this.props;
        const prices = this.state.articles.length === 0 ? "" : this.props.lang === "de" ? this.state.articles.german : this.state.articles.english;

        return (
            <MainPage title={<FormattedMessage
                id="navbar.contact.order"
                defaultMessage="Orderform"
            />}>
                <Grid container className={classes.container}>
                    <Grid className={classes.grid}
                          item
                          xl={9}
                          lg={9}
                          sm={12}
                          xs={12}>
                        <p>
                            <FormattedMessage
                                id="order.desc"
                                defaultMessage="To purchase our service and products, please send us the completed order form."
                            /><br/>
                            <FormattedMessage
                                id="order1"
                                defaultMessage="You can see the prices below or download them in the current price list."
                            />
                        </p>
                        <ContactBlock
                            lang={this.props.lang}
                        />

                        {prices === undefined ? "Sorry, could not load price ist." : <div>
                            <h2 className={classes.padTop}>
                            <FormattedMessage
                                id="navbar.cnc.service"
                                defaultMessage="CNC-Service"
                            />
                        </h2>
                        <table className={classes.table}>
                            <tbody>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.hourlyRateLocation.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.hourlyRateLocation.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.holidayHourlyRate.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.holidayHourlyRate.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.travelExpenses.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.travelExpenses.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.hourlyRateDrive.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.hourlyRateDrive.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.kilometer.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.kilometer.price}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <h2 className={classes.padTop}>
                            <FormattedMessage
                                id="navbar.cnc.monitoring"
                                defaultMessage="CNCMonitoring"
                            />
                        </h2>
                        <table className={classes.table}>
                            <tbody>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.cncMonitoring.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.cncMonitoring.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.cncMonitoringPC.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.cncMonitoringPC.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.lanMpi.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.lanMpi.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.netDevice.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.netDevice.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.packageMonitoringManager.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.packageMonitoringManager.price}
                                </td>
                            </tr>
                            </tbody>
                        </table>


                        <h2 className={classes.padTop}>
                            <FormattedMessage
                                id="navbar.cnc.manager"
                                defaultMessage="CNCManager"
                            />
                        </h2>
                        <table className={classes.table}>
                            <tbody>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.cncManager1.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.cncManager1.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.cncManager2.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.cncManager2.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.cncManager2Limited.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.cncManager2Limited.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.adapterCable.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.adapterCable.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.transferCable.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.transferCable.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.extensionCable.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.extensionCable.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.adapterPlug.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.adapterPlug.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.adapterPlugNine.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.adapterPlugNine.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.zeroModem.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.zeroModem.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.rs232.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.rs232.price}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <h2 className={classes.padTop}>
                            <FormattedMessage
                                id="emailServer"
                                defaultMessage="Email server"
                            />
                        </h2>
                        <table className={classes.table}>
                            <tbody>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.emailServer.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.emailServer.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.emailServerCable.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.emailServerCable.price}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tdStyle}>
                                    {prices.emailServer8.name}
                                </td>
                                <td className={classes.tdStyleRight}>
                                    {prices.emailServer8.price}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            <br/>
                            <FormattedMessage
                                id="order2"
                                defaultMessage="Prices are valid until 31.12.2024. All prices exclude VAT"
                            /><br/>
                            <FormattedMessage
                                id="order3"
                                defaultMessage="The sale is only to professionals. Flight and accommodation costs (after settlement) may be added for service orders."
                            />
                        </p>
                        </div>}
                    </Grid>
                    <QuickAccessBox>
                        <MyIconButton
                            to={getDoc(this.props.lang).order.src}
                            extern={true}
                            toText={getDoc(this.props.lang).order.alt}
                            icon={<OpenInNew/>}
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).price.src}
                            extern={true}
                            toText={getDoc(this.props.lang).price.alt}
                            icon={<OpenInNew/>}
                        />
                    </QuickAccessBox>
                </Grid>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(ContactOrder);