import React, {useState} from "react";
import {
    AppBar, Collapse,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles, Toolbar,
} from "@material-ui/core";
import {Link, NavLink} from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from "@material-ui/icons/Menu";
import fonts from "../../res/fonts";
import spacer from "../../res/spacer";
import HorizontalGradient from "../HorizontalGradient";
import palette from "../../res/palette";
import typography from "../../res/typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import getImage from "../../res/imgs";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: palette.white,
        height: '56px',
        display: 'flex',
        justifyItems: 'center',
    },
    toolbar: theme.mixins.toolbar,
    link: {
        textDecoration: 'none',
        fontFamily: fonts.nFamily,
        fontSize: fonts.nSizeMobile,
        fontStyle: 'italic',
        color: fonts.nColor,
        maxHeight: spacer.footerHeight + spacer.navBarHeight,
    },
    subList: {
        display: 'list',
    },
    subLink: {
        textDecoration: 'none',
        fontFamily: fonts.nFamily,
        fontSize: typography.evenSmallText,
        fontStyle: 'italic',
        color: fonts.nColor,
        maxHeight: spacer.footerHeight + spacer.navBarHeight,
        paddingLeft: spacer.evenBig,
    },
    icon: {
        color: "black",
    },
    center: {
        display: 'flex',
        marginLeft: "auto",
        marginRight: "auto",
    },
    img: {
        maxWidth: '320px',
    },
    imgSmall: {
        maxWidth: '200px',
    },
    inLine: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
    },
    subIcon: {
        paddingLeft: spacer.small,
    },
    collapse: {
        paddingBottom: spacer.big,
    }
}));

function DrawerComponent(props) {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showSub, setShowSub] = useState(false);
    const [showSubContact, setShowSubContact] = useState(false);

    function reset() {
        setOpenDrawer(false);
        setShowSub(false);
        setShowSubContact(false);
    }

    return (
        <>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    {openDrawer ? <CloseIcon className={classes.icon} onClick={() => reset()}/> :
                        <MenuIcon className={classes.icon} onClick={() => setOpenDrawer(true)}/>}
                    <div className={classes.center}>
                        <NavLink to={"/"}>
                            <img
                                className={props.isMobile > 420 ? classes.img : classes.imgSmall}
                                src={getImage(props.lang).header.src}
                                alt={getImage(props.lang).header.alt}/>
                        </NavLink>
                    </div>
                </Toolbar>
                <HorizontalGradient/>
            </AppBar>
            <Drawer
                variant="persistent"
                anchor="left"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <div className={classes.toolbar}/>
                <List>
                    <ListItem onClick={() => reset()}>
                        <ListItemText>
                            <Link to="/" className={classes.link}>
                                <FormattedMessage
                                    id="navbar.home"
                                    defaultMessage="Home"
                                />
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <HorizontalGradient/>
                    <ListItem onClick={() => reset(false)}>
                        <ListItemText>
                            <Link to="/About" className={classes.link}>
                                <FormattedMessage
                                    id="navbar.about"
                                    defaultMessage="About us"
                                />
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <HorizontalGradient/>
                    <ListItem>
                        <ListItemText>
                            <div className={classes.inLine}>
                                <Link to="/CNC" className={classes.link} onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.cnc"
                                        defaultMessage="CNC-Solutions"
                                    />
                                </Link>
                                {showSub ?
                                    <ExpandLessIcon className={classes.subIcon} onClick={() => setShowSub(false)}/> :
                                    <ExpandMoreIcon className={classes.subIcon} onClick={() => setShowSub(true)}/>}
                            </div>
                        </ListItemText>
                    </ListItem>
                    <Collapse in={showSub} timeout="auto" unmountOnExit className={classes.collapse}>
                        <List component="div" disablePadding>
                            <ListItemText>
                                <Link to="/CNC/Monitoring" className={classes.subLink}
                                      onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.cnc.monitoring"
                                        defaultMessage="CNCMonitoring"
                                    />
                                </Link>
                            </ListItemText>
                            <ListItemText>
                                <Link to="/CNC/Manager" className={classes.subLink}
                                      onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.cnc.manager"
                                        defaultMessage="CNCManager"
                                    />
                                </Link>
                            </ListItemText>
                            <ListItemText>
                                <Link to="/CNC/Service" className={classes.subLink}
                                      onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.cnc.service"
                                        defaultMessage="CNC-Service"
                                    />
                                </Link>
                            </ListItemText>
                            <ListItemText>
                                <Link to="/CNC/Steuerungen" className={classes.subLink}
                                      onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.cnc.steuerungen"
                                        defaultMessage="Controller"
                                    />
                                </Link>
                            </ListItemText>
                        </List>
                    </Collapse>
                    <HorizontalGradient/>
                    <ListItem onClick={() => reset()}>
                        <ListItemText>
                            <Link to="/IT" className={classes.link}>
                                <FormattedMessage
                                    id="navbar.it"
                                    defaultMessage="IT-Support"
                                />
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <HorizontalGradient/>
                    <ListItem>
                        <ListItemText>
                            <div className={classes.inLine}>
                                <Link to="/Contact" className={classes.link} onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.contact"
                                        defaultMessage="Contact"
                                    />
                                </Link>
                                {showSubContact ?
                                    <ExpandLessIcon className={classes.subIcon}
                                                    onClick={() => setShowSubContact(false)}/> :
                                    <ExpandMoreIcon className={classes.subIcon}
                                                    onClick={() => setShowSubContact(true)}/>}
                            </div>
                        </ListItemText>
                    </ListItem>
                    <Collapse in={showSubContact} timeout="auto" unmountOnExit className={classes.collapse}>
                        <List component="div" disablePadding>
                            <ListItemText>
                                <Link to="/Contact/Order" className={classes.subLink}
                                      onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.contact.order"
                                        defaultMessage="Order"
                                    />
                                </Link>
                            </ListItemText>
                            <ListItemText>
                                <Link to="/Contact/Downloads" className={classes.subLink}
                                      onClick={() => reset()}>
                                    <FormattedMessage
                                        id="navbar.contact.downloads"
                                        defaultMessage="Downloads"
                                    />
                                </Link>
                            </ListItemText>
                        </List>
                    </Collapse>
                    <HorizontalGradient/>
                </List>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
                <MenuIcon/>
            </IconButton>
        </>
    );
}

export default DrawerComponent;