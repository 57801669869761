import React, {Component} from "react";
import Faq from "react-faq-component";
import getQuestion from "../../../res/faq";
import palette from "../../../res/palette";
import typography from "../../../res/typography";
import fonts from "../../../res/fonts";
import spacer from "../../../res/spacer";

const styles = {
    bgColor: palette.grey.main,
    titleTextColor: 'black',
    rowTitleColor: 'black',
    rowTitleTextSize: typography.iconText,
    rowContentTextSize: fonts.mSize,
    rowContentPaddingTop: spacer.medium,
    rowContentPaddingBottom: spacer.medium
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

class CNCManagerFAQElement extends Component {

    render() {
        return (
            <div>
                <Faq
                    data={getQuestion(this.props.lang)}
                    styles={styles}
                    config={config}
                />
            </div>
        );
    }
}

export default CNCManagerFAQElement;