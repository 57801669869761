import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import spacer from "../res/spacer";
import typography from "../res/typography";
import {FormattedMessage} from "react-intl";
import StyledLink from "./NavBar/StyledLink";
import palette from "../res/palette";

const styles = () => ({
    container: {
        paddingTop: spacer.medium,
        paddingBottom: spacer.medium,
    },
    grid: {},
    head: {
        fontWeight: 'bold',
        fontSize: typography.headerText,
    },
    link: {},
    divWrapper: {
        position: 'relative',
        padding: `${spacer.small} ${spacer.medium} ${spacer.small} 0`,
    },
    divText: {
        height: '48px',
    },
    divRight: {
        textAlign: 'right',
        paddingRight: spacer.medium,
        paddingTop: spacer.medium,
    },
    image: {
        maxWidth: '100%',
        border: palette.border.main,
    },
    innerGrid: {
        padding: `${spacer.small} ${spacer.big} ${spacer.small} 0`,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    white: {
        color: '100%',
        fontStyle: 'italic',
    },
})

class CNCElement extends Component {
    render() {
        const {classes} = this.props;

        return (
            <Grid className={classes.container}
                  container
            >
                <Grid
                    className={classes.grid}
                    item
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}>
                    <div className={classes.divWrapper}>
                        <div className={classes.head}>{this.props.toText}</div>
                    </div>
                </Grid>
                <Grid
                    className={classes.grid}
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}>
                    <div className={classes.divWrapper}>
                        <img className={classes.image} src={this.props.imageSrc}
                             alt={this.props.imageAlt}/></div>
                </Grid>
                <Grid
                    className={classes.grid}
                    item
                    xl={8}
                    lg={8}
                    sm={8}
                    xs={12}>
                    <div className={classes.innerGrid}>
                        <div className={classes.divText}>{this.props.text}</div>
                        <div className={classes.divRight}>
                            <br/>
                            <StyledLink
                                className={classes.white}
                                to={this.props.to}>
                                <FormattedMessage
                                    id="more"
                                    defaultMessage="More information"
                                />
                            </StyledLink>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(CNCElement);