import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../../components/MainPage";
import CNCManagerCarousel from "./CNCManagerCarousel";
import MyButton from "../../../components/Basics/MyButton";
import {FormattedMessage} from "react-intl";
import getDoc from "../../../res/docs";
import {OpenInNew} from "@material-ui/icons";
import MyIconButton from "../../../components/Basics/MyIconButton";
import spacer from "../../../res/spacer";
import QuickAccessBox from "../../../components/QuickAccessBox";

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacer.bigger,
    },
    padTop: {
        paddingTop: '24px'
    }
})

class CNCManager extends Component {

    componentDidMount() {
        document.title = 'CNCManager'
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={<FormattedMessage
                id="navbar.cnc.manager"
                defaultMessage="CNCManager"
            />}>
                <Grid container className={classes.container}>
                    <Grid className={classes.grid}
                          item
                          xl={9}
                          lg={9}
                          sm={12}
                          xs={12}>
                        <h2>
                            <FormattedMessage
                                id="cnc.manager.header"
                                defaultMessage="Up-to-date CNC-Management"
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id="cnc.manager1"
                                defaultMessage="To be better than the competitor means today to deliver the customers products in high quality in the shortest time. To get closer to an efficient production, you have to use a modern CNC-Management."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager2"
                                defaultMessage="The rapid access and editing of CNC-Programs allows also a reduction of setup times since the programs can be transferred to any machine (of course only under the same machines and control types)."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager3"
                                defaultMessage="This is an hourly rate of 80$ to 200$, especially advantageous, because you don't have to change the program at the machine but rather produce or modify the programs at the PC, so the machine can continue working in time. After a short test at the machine, the transfer can be further-produced."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager3.1"
                                defaultMessage="Beside the flexible shipment of CNC-Programs and the machines, the CNCManager offers itself also for an essentially improved data security."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager4"
                                defaultMessage="You/they can save all your data like, for example NC machine data, secure Tool-Offsets, PLC machine data.... (the list can be continued at will)."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager5"
                                defaultMessage="Do you know how expensive a data loss is, for example by empty batteries or by a memory error?"
                            />
                        </p>
                        <center>
                            <b>
                                <br/>
                                <FormattedMessage
                                    id="cnc.manager.solution"
                                    defaultMessage="We have the solution!"
                                />
                                <br/>
                                <br/>
                            </b>
                        </center>
                        <p>
                            <FormattedMessage
                                id="cnc.manager6"
                                defaultMessage="You can easily transfer datas for example with Siemens or Sinumerik (810, 820, 850, 840, 840D, 3M/T.....)."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager7"
                                defaultMessage="Fanuc is also possible ( 0M, 6M, 10M, 11M, 18M, 18i, 21M, 210i.......)."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager8"
                                defaultMessage="The most CNC-Controllers are connectable to this program."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.manager11"
                                defaultMessage="The CNCManager is available in two different versions: CNCManager and CNCManager_2."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager12"
                                defaultMessage="The first version is fully functional, but there is no further development. The CNCManager_2 on the other hand is a completely new development and was developed in the style of the popular CNCManager. The program continues to support older machines as well as the most modern CNC machines."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager13"
                                defaultMessage="With the new CNCManager_2, data can also be transferred from older CNC controls without any problems with new modern Windows 10 64 bit systems."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager14"
                                defaultMessage="Also possible are diagnostic options for machine status and support for fault diagnosis with recording options for PLC signals."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager15"
                                defaultMessage="The CNCManager_2 is the ultimate data backup, data transfer and diagnostic tool."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.manager16"
                                defaultMessage="The program was developed in C#."
                            />
                        </p>
                        <h2 className={classes.padTop}>
                            <FormattedMessage
                                id="cnc.testversion"
                                defaultMessage="Testversion"
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id="cnc.manager.testversion1"
                                defaultMessage="Feel free to test the CNCManager for free and without any obligations. Convince yourself of our optimal software solution."
                            /><br/>
                            <FormattedMessage
                                id="cnc.manager.testversion2"
                                defaultMessage="To do this, download the trial version and unzip it, e.g. with 7 Zip.You can find the test version in the quick access or in our downloads in the menu item contact."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.manager.testversion3"
                                defaultMessage="To install and use the application you need Windows 10 (64 bit) and .NET6 Desktop."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.manager.testversion4"
                                defaultMessage="After purchasing the license of the CNCManager, you can use the program without restrictions."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.windowsBlock"
                                defaultMessage="Windows blocks files from the Internet. To be able to use the application nevertheless, you must allow access in the properties of the executable file in the General tab. Alternatively, you can use this application on another computer by loading the file onto an external disk and then running the installation from there."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.bugs"
                                defaultMessage="In case of occurring errors or undesired misbehavior, we ask you to inform us about them including the exact description, so that we can fix them as soon as possible."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.writeUs"
                                defaultMessage="We would be happy to receive feedback from you so that we can consider your suggestions for improvement and wishes."
                            />
                        </p>
                    </Grid>
                    <QuickAccessBox>
                        <MyButton
                            to={'/CNC/Manager/FAQ'}
                            toText={'FAQ'}
                        />
                        <MyButton
                            to={'/Contact/Order'}
                            toText={
                                <FormattedMessage
                                    id="navbar.contact.order"
                                    defaultMessage="Order"
                                />
                            }
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).cncManager2.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncManager2.alt}
                            icon={<OpenInNew/>}
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).cncManager2Testversion.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncManager2Testversion.alt}
                            icon={<OpenInNew/>}
                        />
                    </QuickAccessBox>
                </Grid>
                <CNCManagerCarousel isMobile={this.props.isMobile <= 1080}
                                    size={this.props.isMobile}
                                    lang={this.props.lang}></CNCManagerCarousel>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(CNCManager);