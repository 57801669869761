import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import HomeCarousel from "./HomeCarousel";
import {FormattedMessage} from "react-intl";

const styles = () => ({
    container: {},
    padTop: {
        paddingTop: '24px'
    }
})

class Home extends Component {
    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'Startseite' : 'Home';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage>
                <HomeCarousel isMobile={this.props.isMobile <= 1080} size={this.props.isMobile}
                              lang={this.props.lang}></HomeCarousel>
                <h2 className={classes.padTop}>
                    <FormattedMessage
                        id="ourOffer"
                        defaultMessage="Our offer"
                    />
                </h2>
                <p>
                    <FormattedMessage
                        id="about1"
                        defaultMessage="Lütje Steuerungstechnik GmbH & Co. KG specialises in CNC control technology. With many years of experience, we offer the best service around your CNC machines, from repair to commissioning of the electronics. The two products we have developed, CNCManager and CNCMonitoring, support you in using your CNC machines successfully while minimising costs. In addition, we offer IT services individually tailored to your needs. We are your reliable partner for the procurement and installation of hardware and software products in physical and virtual IT environments."
                    />
                </p>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(Home);