import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import spacer from "../res/spacer";
import typography from "../res/typography";
import MyButton from "./Basics/MyButton";

const styles = () => ({
    container: {
        paddingTop: spacer.medium,
        paddingBottom: spacer.medium,
    },
    grid: {},
    head: {
        fontWeight: 'bold',
        fontSize: typography.headerText,
        display: 'flex',
        flexDirection: 'row',
    },
    link: {},
    divWrapper: {
        position: 'relative',
        padding: `${spacer.small} ${spacer.medium} ${spacer.small} 0`,
    },
    divText: {},
    divRight: {},
    image: {
        width: '100%',
    },
    innerGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    noBold: {
        fontSize: typography.mediumText,
        fontWeight: 'normal',
        paddingLeft: spacer.small
    }
})

class DownloadElement extends Component {
    render() {
        const {classes} = this.props;

        return (
            <Grid className={classes.container}
                  container
            >
                <Grid
                    className={classes.grid}
                    item
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}>
                    <div className={classes.divWrapper}>
                        <div className={classes.head}>
                            {this.props.toText}
                            <div
                                className={classes.noBold}>{this.props.version ? `(v${this.props.version})` : null}</div>
                        </div>
                    </div>
                </Grid>
                <Grid
                    className={classes.grid}
                    item
                    xl={12}
                    lg={12}
                    sm={12}
                    xs={12}>
                    <div className={classes.divWrapper}>
                        <Grid container className={classes.innerGrid}>
                            <Grid>
                                <div className={classes.divText}>{this.props.text}</div>
                            </Grid>
                            <Grid>
                                <div className={classes.divRight}>
                                    {this.props.to ? <MyButton
                                        to={this.props.to}
                                        toText={this.props.toText}
                                    /> : null}
                                    {this.props.children}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(DownloadElement);