import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../../components/MainPage";
import spacer from "../../../res/spacer";
import CNCMonitoringCarousel from "./CNCMonitoringCarousel";
import {FormattedMessage} from "react-intl";
import MyIconButton from "../../../components/Basics/MyIconButton";
import getDoc from "../../../res/docs";
import {OpenInNew} from "@material-ui/icons";
import MyButton from "../../../components/Basics/MyButton";
import QuickAccessBox from "../../../components/QuickAccessBox";

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacer.bigger,
    },
    list: {
        listStyleType: 'square',
    },
    padTop: {
        paddingTop: '24px'
    }
})

class CNCMonitoring extends Component {

    componentDidMount() {
        document.title = 'CNCMonitoring';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={<FormattedMessage
                id="navbar.cnc.monitoring"
                defaultMessage="CNCMonitoring"
            />}>
                <Grid container className={classes.container}>
                    <Grid className={classes.grid}
                          item
                          xl={9}
                          lg={9}
                          sm={12}
                          xs={12}>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring1"
                                defaultMessage="Monitoring is essential - after all you want to have your production data of your CNC machine under control at any time. With our product CNCMonitoring you can observe important data of your CNC machine via a standard web browser. You can decide for yourself how you want to implement the application. The setup is simple and straightforward, as no further intervention in the machine is required."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring2"
                                defaultMessage="The monitoring data can also be accessed with a mobile device. In the browser of your smartphone, computer or tablet, important status signals can be visualized as well as a diagram showing the production data can be configured. You can define up to eight signals. If a signal you have defined has been reached, an e-mail notification will then be triggered automatically."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring3"
                                defaultMessage="If you already have a PC or IPC that you would like to use for this purpose, you can download the software without obligation and test it for a period of 14 days free of charge (trial version). If you are convinced by the application, you are welcome to purchase a license to use CNCMonitoring without any restrictions."
                            />
                        </p>
                        <b>
                            <FormattedMessage
                                id="cnc.monitoring.question"
                                defaultMessage="What is CNCMonitoring?"
                            />
                        </b>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring4"
                                defaultMessage="The CNCMonitoring consists of three parts:"
                            /><br/>
                            <FormattedMessage
                                id="cnc.monitoring5"
                                defaultMessage="A software that runs on the PC to exchange data with the machine."
                            /><br/>
                            <FormattedMessage
                                id="cnc.monitoring6"
                                defaultMessage="A software running on the PC to display the data via a web browser."
                            /><br/>
                            <FormattedMessage
                                id="cnc.monitoring7"
                                defaultMessage="An IPC running the two programs that communicate with the Siemens 840D SL or Fanuc controller and possibly a module to enable communication with an 840D PL."
                            />
                        </p>
                        <FormattedMessage
                            id="cnc.monitoring.advantages"
                            defaultMessage="Your advantages:"
                        />
                        <ul className={classes.list}>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.advantages1"
                                    defaultMessage="easy and intuitive use of the interfaces"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.advantages2"
                                    defaultMessage="uncomplicated installation and setup without additional software"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.advantages3"
                                    defaultMessage="increase in your productivity"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.advantages4"
                                    defaultMessage="detection of unwanted machine stops"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.advantages5"
                                    defaultMessage="notifications in case of certain conditions (e.g. if an EMERGENCY STOP has been triggered)"
                                />
                            </li>
                        </ul>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring8"
                                defaultMessage="If you would like to have additional functions, please do not hesitate to contact us."
                            />
                        </p>
                        <h2 className={classes.padTop}>
                            <FormattedMessage
                                id="cnc.monitoring.preconditions"
                                defaultMessage="System requirements"
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring9"
                                defaultMessage="If you have a PC or IPC that you want to use for this purpose, then you only need the software package. If you do not have such a PC or IPC, you can purchase one from us already preconfigured with various tools. In the basic stage you do not need any intervention in the machine."
                            /><br/>
                            <FormattedMessage
                                id="cnc.monitoring10"
                                defaultMessage="Please note that all three parts belong together and that these components will run as long as you want to monitor the machine."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring11"
                                defaultMessage="The main focus is on the FANUC controller, because it provides most of the signals. It furthermore likewise works the Siemens 840D SL as well as the PL. The 840D does not require any software from Siemens on the PC. The FANUC control also does not require any additional software."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring12"
                                defaultMessage="System requirements are Windows® 10 64Bit and .NET6 Desktop."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring13"
                                defaultMessage="Please note that you only use the PC for CNCMonitoring, as a web server is also set up to run all the time. The CNCMonitoring is included in the autostart. To use the web service, it must be allowed in the firewall."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring14"
                                defaultMessage="To install the CNCMonitoring you must be logged in locally as administrator. This means that you cannot be logged in as a domain member during the installation. If you do not follow this, the CNCMonitoring software product may not be installed correctly. After the installation, this PC serves as a web server to display the production data via HTML."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring15"
                                defaultMessage="Furthermore, a free hard disk space of approx. 80MB is required."
                            />
                        </p>
                        <FormattedMessage
                            id="cnc.monitoring.components"
                            defaultMessage="With the installation the following components are installed:"
                        />
                        <ul className={classes.list}>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.components1"
                                    defaultMessage="CNCMonitoring"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.components2"
                                    defaultMessage="Visual C++ 2008 redistributable package"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.components3"
                                    defaultMessage="Apache web server"
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.monitoring.components4"
                                    defaultMessage="Web data for monitoring via web browser"
                                />
                            </li>
                        </ul>
                        <h2 className={classes.padTop}>
                            <FormattedMessage
                                id="cnc.testversion"
                                defaultMessage="Testversion"
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring.testversion1"
                                defaultMessage="You may try the CNCMonitoring in its entirety for 14 days free of charge, without obligation and without any commitment."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring.testversion2"
                                defaultMessage="In the test version you will be reminded regularly that this is a limited version - so you have to restart CNCMonitoring after eight hours. After purchasing the license of CNCMonitoring, you can use the program without any limitations and without any further interruptions."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring.testversion3"
                                defaultMessage="To do this, download the trial version and unzip it, e.g. with 7 Zip.You can find the test version in the quick access or in our downloads in the menu item contact."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring.testversion4"
                                defaultMessage="Please note that you use the PC only for the CNCMonitoring, because a web server is also set up, which is constantly running. The CNCMonitoring will be included in the autostart. To use the web service, it must be allowed within the firewall."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.monitoring.testversion5"
                                defaultMessage="To install and use the application, you need Windows 10 (64 bit), .NET5, about 80 MB of disk space and administrator rights."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.windowsBlock"
                                defaultMessage="Windows blocks files from the Internet. To be able to use the application nevertheless, you must allow access in the properties of the executable file in the General tab. Alternatively, you can use this application on another computer by loading the file onto an external disk and then running the installation from there."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.bugs"
                                defaultMessage="In case of occurring errors or undesired misbehavior, we ask you to inform us about them including the exact description, so that we can fix them as soon as possible."
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="cnc.writeUs"
                                defaultMessage="We would be happy to receive feedback from you so that we can consider your suggestions for improvement and wishes."
                            />
                        </p>
                    </Grid>
                    <QuickAccessBox>
                        <MyButton
                            to={'/Contact/Order'}
                            toText={
                                <FormattedMessage
                                    id="navbar.contact.order"
                                    defaultMessage="Order"
                                />
                            }
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).cncMonitoring.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncMonitoring.alt}
                            icon={<OpenInNew/>}
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).cncMonitoringTestversion.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncMonitoringTestversion.alt}
                            icon={<OpenInNew/>}
                        />
                    </QuickAccessBox>
                </Grid>
                <CNCMonitoringCarousel isMobile={this.props.isMobile <= 1080}
                                       size={this.props.isMobile}
                                       lang={this.props.lang}></CNCMonitoringCarousel>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(CNCMonitoring);