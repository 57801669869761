import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import spacer from "../../res/spacer";
import palette from "../../res/palette";
import StyledLink from "../NavBar/StyledLink";

const styles = () => ({
    button: {
        width: '180px',
        padding: '5px 13px',
        marginTop: spacer.medium,
        fontSize: '18px',
        border: palette.border.main,
        borderRadius: '0.2em 0.2em 0.2em',
        backgroundColor: palette.white,
        textAlign: 'center',
        fontStyle: 'italic',
    },
    inLine: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '5px',
    },
    link: {
        color: 'black',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline',
        },
    }
})

class MyIconButton extends Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.button}>
                {this.props.extern ?
                    <a className={classes.link} href={this.props.to} target={"_blank"} rel={"noreferrer noopener"}>
                        <div className={classes.inLine}>
                            {this.props.toText}
                            <div className={classes.icon}>{this.props.icon}</div>
                        </div>
                    </a>
                    : <StyledLink to={this.props.to}>
                        <div className={classes.inLine}>
                            {this.props.toText}
                            <div className={classes.icon}>{this.props.icon}</div>
                        </div>
                    </StyledLink>
                }
            </div>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(MyIconButton);