import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import MainPage from "../../components/MainPage";
import {FormattedMessage} from "react-intl";
import getDoc from "../../res/docs";
import {OpenInNew} from "@material-ui/icons";
import MyIconButton from "../../components/Basics/MyIconButton";
import ContactBlock from "../../components/ContactBlock";
import Obfuscate from "react-obfuscate";

const styles = () => ({
    language: {
        textDecoration: 'none',
        color: 'black',
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    tel: {
        textDecoration: 'none',
        color: 'black',
        "&:hover": {
            textDecoration: 'none',
        },
    }
})

class Imprint extends Component {

    componentDidMount() {
        document.title = this.props.lang === 'de' ? 'Impressum' : 'Imprint';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={
                <FormattedMessage
                    id="footer.imprint"
                    defaultMessage="Legal Notice"
                />
            }>
                <ContactBlock
                    lang={this.props.lang}
                />
                <p>
                    <FormattedMessage
                        id="imprint.boss"
                        defaultMessage="Managing director: Thomas Lütje"
                    /><br/>
                    <FormattedMessage
                        id="imprint.hra"
                        defaultMessage="Local court of Freiburg HRA 701447"
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="imprint.gmbh"
                        defaultMessage="Personally liable partner:"
                    /><br/>
                    <FormattedMessage
                        id="imprint.verwaltung"
                        defaultMessage="Lütje Verwaltungs-GmbH"
                    /><br/>
                    <FormattedMessage
                        id="imprint.sitz"
                        defaultMessage="Location: Stockach"
                    /><br/>
                    <FormattedMessage
                        id="imprint.hrb"
                        defaultMessage="Local court of Freiburg HRB 703565"
                    /><br/>
                    <br/>
                    <FormattedMessage
                        id="imprint.ust1"
                        defaultMessage="VAT registration no.: "
                    />
                    <Obfuscate
                        className={classes.tel}
                        tel={"DE264287951"}
                    /><br/>
                </p>
                <p>
                    <FormattedMessage
                        id="imprint.haft"
                        defaultMessage="Liability hint:"
                    /><br/>
                    <FormattedMessage
                        id="imprint.hin"
                        defaultMessage="Despite careful textual control, we do not take liability for the contents of the external links. For the content of the linked sides, exclusively their operator is responsible."
                    />
                </p>
                    <MyIconButton
                        to={getDoc(this.props.lang).agb.src}
                        extern={true}
                        toText={getDoc(this.props.lang).agb.short}
                        icon={<OpenInNew/>}
                    />
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(Imprint);