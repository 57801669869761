const german = {
    rows: [
        {
            title: "Was bedeutet RS232?",
            content: "Die RS232 Schnittstelle (auch V.24 genannt) ist eine genormte Datenübertragungsschnittstelle, mit der sich verschiedene Geräte verbinden lassen wie z.B. Modems, Computer, Drucker, Lesegeräte, CNC-Steuerungen, Digitale Kameras, Steuereinheiten, ..."
        },
        {
            title: "Wie lang darf das Kabel zu der Maschine sein?",
            content: "Die Kabellänge sollte 30m nicht überschreiten (es ist von Steuerungstyp zu Steuerungstyp sehr verschieden, manche schaffen 15 m und andere haben mit 100 m keine Probleme). Falls Sie 30 m überschreiten, empfehle ich einen Verstärker (Repeater), um die Übertragungssicherheit zu erhöhen."
        },
        {
            title: "Was für ein Computer kann verwendet werden?",
            content: "In den meisten Fällen wird ein Desktop Computer verwendet, der idealerweise unter Windows 10 läuft und der fest zu den Maschinen verdrahtet wird. Empfehlenswert ist auch ein Laptop, mit dem Sie direkt an der Maschine die Daten sichern oder einspielen können. Diverse Kunden benutzen auch einen kleinen Speicher, mit dem Sie die Daten von dem Computer auf den Speicher laden, zur Maschine laufen und dort in die Maschine einspielen und umgekehrt (empfehlenswert in einer sehr schmutzigen Umgebung, da Laptops sehr empfindlich auf Schmutz reagieren)."
        },
        {
            title: "Was ist das Einsatzgebiet des CNCManagers?",
            content: "Der CNCManager kommt überall da zum Einsatz, wo Daten von CNC-Maschinen gesichert werden müssen. In einer CNC-Maschine sind die Daten normalerweise in einem gepuffertem RAM abgelegt, dort kann es hin und wieder zu einem Datenverlust führen. Falls Sie schon einmal einen Datenverlust hatten, wissen Sie bestimmt, was für Kosten dann auf Sie zukommen wie z.B. der Monteureinsatz und die Maschinenstillstandszeit. Bei älteren Maschinen sind die Daten ab und an nicht mehr zu beschaffen, das bedeutet evtl. sehr lange Stillstandszeiten, dass die CNC-Steuerung in diesem Fall neu Inbetrieb genommen werden muss, mit mehreren Tagen muss hier gerechnet werden. Mit dem CNCManager können Sie Ihre Daten sichern und Sie sind in diesem Fall gut abgesichert."
        },
        {
            title: "Was bedeutet \"remote buffer\"?",
            content: "Remote buffer oder Blockweise nachladen bedeutet, dass sich das CNC-Programm nicht in der CNC-Steuerung befindet, sondern in einem externen Speicher, d.h. der Speicher der CNC-Steuerung ist normalerweise sehr klein und wenn große Programme bearbeitet werden müssen, die nicht in den Speicher der CNC-Steuerung passen, muss ein externer Speicher verwendet werden."
        },
        {
            title: "Welche CNC-Steuerungen können angeschlossen werden?",
            content: "Anschließbar sind Siemens und Fanuc Steuerungen, die über eine RS 232 Schnittstelle verfügen. Andere Steuerungen sind HK, Grundig, Heidenhain oder ähnliche. Mazak ist momentan nicht möglich."
        },
        {
            title: "Wie kann ich den CNCManager freischalten?",
            content: "Um den CNCManager freizuschalten, kontaktieren Sie bitte das Unternehmen Lütje Steuerungstechnik GmbH & Co. KG. Sie bezahlen den CNCManager und dann bekommen Sie per E-Mail, Fax oder Brief den Freischaltungscode, mit dem Sie den CNCManager uneingeschränkt nutzen können."
        },
        {
            title: "Können CNC Programme editiert werden?",
            content: "Mit dem CNCManager können natürlich Daten oder CNC-Programme editiert werden. Allerdings ist kein Syntaxcheck eingebaut, das bedeutet, dass Sie sich um das Format, das die CNC-Steuerung versteht, selbst kümmern müssen. Es können mehrere Fenster geöffnet werden, unter denen Daten einfach kopiert werden können."
        },
        {
            title: "Ist es möglich, mit dem CNCManager Geld zu sparen?",
            content: "Natürlich kann mit dem CNCManager sehr viel Geld gespart werden. Denken Sie nur an einen Stundensatz von 80,- bis 200,-€ pro Maschine und Stunde. Sie können einfach von einer zur anderen Maschine Daten übertragen oder Sie können CNC-Programme am PC ändern oder erstellen. In dieser Zeit läuft die Maschine weiter und kann Teile produzieren, ..."
        }
    ]
}

const english = {
    rows: [
        {
            title: "What means RS 232?",
            content: "The RS 232 interface, also called V.24, is a standardized data transfer interface, where different appliances can be connected, like CNC-Controllers reading appliances, digital cameras, control units, like modems, computers,....."
        },
        {
            title: "How long can the cable to the machine be?",
            content: "The cable length should not exceed 30 m, it is very different from CNC-Controller type to CNC-Controller type, some manage, 15 m have others no problems with 100 m. If you exceed 30 m, I recommend an intensifier to increase repeater about the transfer security."
        },
        {
            title: "What type of computer can be used?",
            content: "In most cases, a desktop computer is used, that runs under Windows 10 and he/it is wired solidly to the machines. A laptop, where you secure or play in the data directly at the machine is commendable. Several customers are using a small storage, that allows you to load the data from the computer to the storage, runs the machine and plays there in the machine and turns back, commendable in a very dirty surroundings, since laptops react very sensitively to dirt."
        },
        {
            title: "What is the application of the CNCManager?",
            content: "The CNCManager is used anywhere, where data of CNC-Machines have to be secured. In a CNC-Machine, the data are usually stored in a RAM, where it now can lead to a data loss. If you ever had a data loss you definitely know, which costs you have to pay. The assembler use and the machine halt time. In older machines, the data are sometimes not to obtain, that means possibly a very long downtime, which the CNC-Controller must become again reinitialized in this case, with several days. With the CNCManager, you can secure your data and are in this case secured well for you."
        },
        {
            title: "What does \"remote buffer\" mean?",
            content: "Remote buffer or block manner means, that the CNC-Program is not in the CNC-Controller, but in an external storage, i.e. the CNC-Controller-Memory is normally very small and if big programs have to be edited, that don't fit into this storage, you have to use an external memory."
        },
        {
            title: "Which CNC-Controllers can be connected?",
            content: "Connectable are Siemens and Fanuc controls, which provides a RS 232 interface at his disposal. Other controls are heath grove, or similar, HK, Grundig. Mazak is momentarily not possible."
        },
        {
            title: "How I can unlock the CNCManager?",
            content: "To unlock the CNCManager, contact please the company Lütje Steuerungstechnik GmbH & Co. KG.. You pay the CNCManager and then you get a free switch code via e-mail, fax or letter. Then you can use the CNCManager fully."
        },
        {
            title: "Can I edit CNC-Programs?",
            content: "Of course you can edit with the CNCManager data or CNC-Programs, but there is no syntax-checks built-in, which means, that you have to take care of the format, that understands the CNC-Controller itself. Several windows can be opened, where data can simply be copied."
        },
        {
            title: "Is it possible to save money with the CNCManager?",
            content: "Of course, a lot of money can be saved with the CNCManager. Think only at an hourly rate of 80$ to 200·$ per machine and hour. You can simply transfer data from one to the other machine, or you can alter or produce CNC-Programs at the PC. In this time, the machine runs further and can produce parts..."
        }
    ]
}

export default function getQuestion(lang) {
    if (lang === 'de') {
        return german
    } else {
        return english
    }
}