import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import spacer from "../res/spacer";
import palette from "../res/palette";
import fonts from "../res/fonts";
import './styles.scss'

const styles = () => ({
    wrapper: {
        fontFamily: fonts.mFamily,
        color: fonts.mColor,
        lineHeight: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.grey.main,
    },
    container: {
        position: 'relative',
        minHeight: '100vh',
        width: spacer.pageWidth,
        maxWidth: spacer.pageWidth,
    },
    main: {
        padding: `${spacer.big} ${spacer.big} ${spacer.bigger} ${spacer.big}`,
    },
    headerStyle: {
        textAlign: 'center',
        textDecoration: 'none',
        paddingBottom: spacer.big,
    },
    headerSubstitute: {
        paddingTop: 0,
    }
})

class MainPage extends Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.wrapper}>
                <div className={classes.container}>
                    <div className={classes.main}>
                        {this.props.title ? <h1 className={classes.headerStyle}>{this.props.title}</h1> : <div className={classes.headerSubstitute}></div>}
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(MainPage);