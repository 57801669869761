import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../../components/MainPage";
import spacer from "../../../res/spacer";
import getImage from "../../../res/imgs";
import {FormattedMessage} from "react-intl";
import QuickAccessBox from "../../../components/QuickAccessBox";
import getDoc from "../../../res/docs";
import {OpenInNew} from "@material-ui/icons";
import MyIconButton from "../../../components/Basics/MyIconButton";
import palette from "../../../res/palette";

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacer.bigger,
    },
    grid: {},
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        maxWidth: '500px',
        width: '100%',
        border: palette.border.main,
    },
    list: {
        listStyleType: 'square',
    }
})

class CNCService extends Component {

    componentDidMount() {
        document.title = 'CNC-Service';
        window.scrollTo(0, 0)
    }

    render() {
        const {classes} = this.props;

        return (
            <MainPage title={<FormattedMessage
                id="navbar.cnc.service"
                defaultMessage="CNC-Service"
            />}>
                <Grid container className={classes.container}>
                    <Grid className={classes.grid}
                          item
                          xl={9}
                          lg={9}
                          sm={12}
                          xs={12}>
                        <ul className={classes.list}>
                            <li>
                                <FormattedMessage
                                    id="cnc.service0"
                                    defaultMessage="Data backup of hard disks and CNC controls: In the event of a hard disk failure, data loss or a total economic loss of the machine can occur."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service1"
                                    defaultMessage="We have spare parts from various Siemens CNC controls in stock. Other parts can be obtained at short notice."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service2"
                                    defaultMessage="Repairs of various machine manufacturers of electronic type."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service3"
                                    defaultMessage="We support you with repairs or extensions of your CNC machine."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service4"
                                    defaultMessage="PLC programming or control upgrades."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service5"
                                    defaultMessage="We repair electronics from various manufacturers."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service6"
                                    defaultMessage="Whether assemblies, plug-in cards or complete devices."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service7"
                                    defaultMessage="CNC controls, screens or drives."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service8"
                                    defaultMessage="Almost 40 years of experience in control and drive technology."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service9"
                                    defaultMessage="We also install assemblies and match them if necessary."
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="cnc.service10"
                                    defaultMessage="Repairs to machines can also be carried out by us on site."
                                />
                            </li>
                        </ul>
                        <p>
                            <FormattedMessage
                                id="question.ask"
                                defaultMessage="Feel free to contact us if you have any questions or interest. We will take care of your request as soon as possible."
                            />
                        </p>
                    </Grid>
                    <QuickAccessBox>
                        <MyIconButton
                            to={getDoc(this.props.lang).spareParts.src}
                            extern={true}
                            toText={getDoc(this.props.lang).spareParts.alt}
                            icon={<OpenInNew/>}
                        />
                    </QuickAccessBox>
                </Grid>
                <p className={classes.wrapper}>
                    <img className={classes.img} src={getImage(this.props.lang).zertifikat.src}
                         alt={getImage(this.props.lang).zertifikat.alt}/>

                </p>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(CNCService);