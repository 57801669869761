import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import MainPage from "../../../components/MainPage";
import MyIconButton from "../../../components/Basics/MyIconButton";
import spacer from "../../../res/spacer";
import typography from "../../../res/typography";
import {OpenInNew} from "@material-ui/icons";
import {FormattedMessage} from "react-intl";
import getDoc from "../../../res/docs";
import DownloadElement from "../../../components/DownloadElement";

const styles = () => ({
    container: {
        paddingTop: spacer.medium,
    },
    divWrapper: {
        position: 'relative',
        padding: `${spacer.small} ${spacer.medium} ${spacer.small} 0`,
    },
    head: {
        fontWeight: 'bold',
        fontSize: typography.headerText,
    },
})

class ContactDownloads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cnc2: {},
            cncMoni: {}
        };
    }

    componentDidMount() {
        document.title = 'Downloads';
        window.scrollTo(0, 0);
        this.getDataCncManager();
        this.getDataCncMoni();
    }

    getDataCncManager() {
        fetch('/data/cncmanag_2/CNC2Version.json')
            .then(res => {
                return res.json();
            })
            .then(data => {
                this.setState({
                    cnc2: data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getDataCncMoni() {
        fetch('/data/cncmonitoring/CNCMoVersion.json')
            .then(res => {
                return res.json();
            })
            .then(data => {
                this.setState({
                    cncMoni: data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getDoc() {

    }

    render() {
        const {classes} = this.props;
        const cncVersion = this.state.cnc2.length === 0 ? "" : this.state.cnc2.version
        const cncVersionDate = this.state.cnc2.length === 0 ? "" : this.state.cnc2.date
        const cncMoniVersion = this.state.cncMoni.length === 0 ? "" : this.state.cncMoni.version
        const cncMoniVersionDate = this.state.cncMoni.length === 0 ? "" : this.state.cncMoni.date

        return (
            <MainPage title={
                <FormattedMessage
                    id="navbar.contact.downloads"
                    defaultMessage="Downloads"
                />
            }>
                <FormattedMessage
                    id="downloads.blocks"
                    defaultMessage="Windows blocks files from the Internet. To be able to use the application nevertheless, you must allow access in the properties of the executable file in the General tab (see example). Alternatively, you can use this application on another computer by loading the file onto an external disk and then running the installation from there."
                />
                <Grid
                    container
                    className={classes.container}
                >
                    <DownloadElement
                        toText={<FormattedMessage
                            id="downloads.info"
                            defaultMessage="Information sheet"
                        />}
                        text={<FormattedMessage
                            id="downloads.info.zsm"
                            defaultMessage="The following document summarizes our two softwares CNCManager and CNCMonitoring. You get all important information in one central document."
                        />}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).cncInfo.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncInfo.alt}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                    <DownloadElement
                        toText={<FormattedMessage
                            id="navbar.cnc.monitoring"
                            defaultMessage="CNCMonitoring"
                        />}
                        version={`${cncMoniVersion}, ${cncMoniVersionDate}`}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).cncMonitoring.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncMonitoring.alt}
                            icon={<OpenInNew/>}
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).cncMonitoringTestversion.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncMonitoringTestversion.alt}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                    <DownloadElement
                        toText={<FormattedMessage
                            id="navbar.cnc.manager"
                            defaultMessage="CNCManager"
                        />}
                        text={<FormattedMessage
                            id="downloads.cncManager.zsm"
                            defaultMessage="Note: The download of the test version includes two files. The signed file (CNCsetup) is suitable for Windows 7 and Windows 10; the unsigned file (CNCsetupXP) for Windows XP."
                        />}
                        version={`5.5.6, 23.01.2023`}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).cncManager1.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncManager1.alt}
                            icon={<OpenInNew/>}
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).cncManager1Testversion.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncManager1Testversion.alt}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                    <DownloadElement
                        toText={<FormattedMessage
                            id="navbar.cnc.manager2"
                            defaultMessage="CNCManager_2"
                        />}
                        version={`${cncVersion}, ${cncVersionDate}`}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).cncManager2.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncManager2.alt}
                            icon={<OpenInNew/>}
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).cncManager2Testversion.src}
                            extern={true}
                            toText={getDoc(this.props.lang).cncManager2Testversion.alt}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                    <DownloadElement
                        toText={<FormattedMessage
                            id="downloads.email"
                            defaultMessage="E-Mail-Server"
                        />}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).emailServer.src}
                            extern={true}
                            toText={getDoc(this.props.lang).emailServer.alt}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                    <DownloadElement
                        toText={<FormattedMessage
                            id="downloads.priceOrder"
                            defaultMessage="Price list and orderform"
                        />}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).order.src}
                            extern={true}
                            toText={getDoc(this.props.lang).order.alt}
                            icon={<OpenInNew/>}
                        />
                        <MyIconButton
                            to={getDoc(this.props.lang).price.src}
                            extern={true}
                            toText={getDoc(this.props.lang).price.alt}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                    <DownloadElement
                        toText={<FormattedMessage
                            id="downloads.spareParts"
                            defaultMessage="Spare parts"
                        />}
                        text={<FormattedMessage
                            id="cnc.service1"
                            defaultMessage="We have spare parts from various Siemens CNC controls in stock. Other parts can be obtained at short notice."
                        />}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).spareParts.src}
                            extern={true}
                            toText={getDoc(this.props.lang).spareParts.alt}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                    <DownloadElement
                        toText={<FormattedMessage
                            id="imprint.conditions"
                            defaultMessage="General business conditions"
                        />}
                        text={''}
                    >
                        <MyIconButton
                            to={getDoc(this.props.lang).agb.src}
                            extern={true}
                            toText={getDoc(this.props.lang).agb.short}
                            icon={<OpenInNew/>}
                        />
                    </DownloadElement>
                </Grid>
            </MainPage>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(ContactDownloads);